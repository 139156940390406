TagEdit.vue

Component to allow user to add, delete, or edit tags for selected videos.


<template>
  <span>
    <tags key="edit"
      placeholder = "Add new tags"
      :tagList="tagList"
      :onAdd="tagsEditAdd"
      :onEditComplete="tagsEditChange"
      :onEditStart="tagsEditChangeStart"
      :onRemove="tagsEditRemove"
      :allowNew="true"
      :unsaved="tagsUnsaved"
      :initialValue="tagsSelected" />
  </span>

</template>

<script>
import Tags from './Tag.vue';

export default {
  name: "TagEdit",

  data () {
    return {
      tagBeingEdited: '',
      tagsInCommon: [],  // tags selected videos have in common
      tagsUnsaved: false
    }
  },

  components: {
    Tags
  },

  created() {
    // console.log('Selected: ', this.selectedList.map(s => s.key));

    // Find the intersection of tags for all selected videos to show the
    // user existing tags.  Start by gathering the tag array from each
    // video into an array of arrays...
    let listOfTags = [];
    const allHaveTags = this.selectedList.every(sv => {
      const tags = this.videoTag.find(v => v.key.includes(sv.key)).tags;
      if (!tags.length) return false;  // break from .every() loop

      // Push references to arrays, not elements, to build an array of arrays.
      listOfTags.push(tags);
      // console.log("Pushing tag array: ", tags);
      return true;
    });
    // console.log('allHaveTags is %s, List of tags: ', allHaveTags, listOfTags);

    // ...then, if all selected videos have tags, find the tags all selected videos
    // have in common.  This is a Nina Scholz answer from
    // Stack Overflow how-to-calculate-intersection-of-multiple-arrays-in-javascript
    // https://stackoverflow.com/questions/37320296
    if (allHaveTags) {
      // With only one video selected, make flat array of tags.
      if (this.selectedList.length === 1)  {
        this.tagsInCommon = listOfTags.flat();
      } else {
        // .reduce goes through 'listOfTags' which is an array of arrays.
        // 'a' is the result of the previous iteration; 'b' is the next item in
        // 'listOfTags'.  The filter runs on the result of the previous iteration,
        // testing each item is included in the next array in 'listOfTags'.
        // Example:  given listOfTags = [ [1,2,3], [2,3,4], [1,3] ]
        // Pass 1: a = [1,2,3]  b = [2,3,4]  result of filter = [2,3]
        // Pass 2: a = [2,3]    b = [1,3]    result of filter = [3]
        this.tagsInCommon = listOfTags.reduce((a, b) => a.filter(c => b.includes(c)));
      }

    // !allHaveTags
    } else this.tagsInCommon = [];

    // console.log('Tags in common: ', this.tagsInCommon);

    this.$store.commit('setTagEditActive', true);
  },

  methods: {
    // Handler called in "edit tags" mode each time the user adds a tag.
    // New tag is passed in 'e.detail.data.value'.
    tagsEditAdd(e) {

      const tag = e.detail.data.value;
      if (tag === '')  { return; }  // do nothing with empty input

      this.tagsEditSave(tag);
    },

    // Handler called in "edit tags" mode when user has finished editing a tag.
    // To edit a tag, the user double-clicks in the tag and edits directly.
    // Mechanism for updating the tag is to remove the original tag and add
    // a new one.
    tagsEditChange(e) {
      // console.log("Tag edit change handler, old / new:", this.tagBeingEdited, e.detail.data.value);
      this.tagsEditSave(e.detail.data.value, this.tagBeingEdited);
      this.tagBeingEdited = '';
    },

    // Handler called in "edit tags" mode when user double-clicks in the tag
    // to start editing.  Called before user makes any changes.  Capture the
    // starting value of the tag.
    tagsEditChangeStart(e) {
      // console.log("Tag edit START value:", e.detail.data.value);
      this.tagBeingEdited = e.detail.data.value;
    },

    // Handler called in "edit tags" mode each time the user deletes a tag.
    tagsEditRemove(e) {

      const tag = e.detail.data.value;
      if (tag === '')  { return; }  // do nothing with empty input

      this.tagsEditSave('', tag);

      // console.log("Tag edit handler, removing tag %s, tagsToRemove[]:", tag, this.tagsToRemove);
    },

    // Handler called when user has finished making changes to a set of tags and
    // clicks the "Save Tags" button.  Changes include typing new tags, deleting,
    // and editing existing tags.
    //
    // Update the record(s) for the video(s) being tagged in the database and
    // in this.videoTag[] in memory with the newly added tags.  Also update the list
    // of all the user's tags, this.tagList[].
    async tagsEditSave(tagToSave, tagToRemove = '') {
      this.tagsUnsaved = true;
      console.log('Saving tag [%s], removing tag [%s]', tagToSave, tagToRemove);

      // Iterate through the selected videos and update the tags field in the database.
      // Array this.selectedList[] is a subset of the list of displayed thumbnails, with
      // 'id', 'key', and 'src' fields.  Use this to locate the record in this.videoTag[],
      // which is all videos from the database, including the existing tags for each video.
      for (const sv of this.selectedList)
      {
        let i = this.videoTag.findIndex(v => v.key.includes(sv.key));
        if (i < 0) { continue; }

        // Start with the video's existing tags. Note that videoTag.tags is
        // never undefined because it is initialized to an empty array in
        // listFolder() if the video has no tags in the database.
        let tags = [...this.videoTag[i].tags];
        // console.log('Starting set of tags for this video:', tags);

        //----- Add new tag -----
        // Testing the parameter 'tagToSave' treats it as a boolean and tests true
        // only if 'tagToSave' is a string with non-zero length.  This works the same
        // as the idomatic !!tagToSave expression, where the first ! converts the
        // string to a boolean and does a "not" on the result.  esLint barfed on using
        // the !! expression here insisting it is redundant.
        if (tagToSave) {

          if (!tags.includes(tagToSave)) {
            tags.push(tagToSave);
            tags.sort();
          }
        }

        //----- Remove existing tag -----
        if (tagToRemove) {

          const idx = tags.indexOf(tagToRemove);
          if (idx >= 0) {
            tags.splice(idx, 1);  // If tagToRemove exists in the array, remove it.
          }
        }

        // Update the in-memory copy of this video's database record.
        this.$store.commit('setVideoTagTags', { idx: i, tags: tags });

        // Update this video's record in the DynamoDB database with the new tags.
        try {
          await this.$store.dispatch('updateVideoTag',
            { sk: this.videoTag[i].sk,
              tags: tags
            });
        } catch (err) { console.log('error: ', err); }
     }

      // Also update the in-memory whitelist (tagList[]) with tags that are new to the user's library.
      if (tagToSave  &&  !this.tagList.some(t => t.value === tagToSave)) {

        this.$store.commit('pushTagList', tagToSave);  // push the tag to the global tagList[]
        console.log('New tagList[]: ', this.tagList.map(t => t.value));
      }

      // If user deleted a tag and it is not used in any other video, remove
      // the deleted tag from the in-memory whitelist too.
      if (!this.videoTag.some(v => v.tags.includes(tagToRemove))) {
        let toast = this.tagList.findIndex(t => t.value === tagToRemove);
        if (toast >= 0) {
          this.$store.commit('removeFromTagList', toast);
        }
      }
      this.tagsUnsaved = false;
    }
  },

  computed: {
    // Global DynamoDB client.
    ddb() {
      return this.$store.state.clientDynamoDB;
    },

    selectedList() {
      return this.$store.state.selected;
    },

    // All user-defined tags for this user.  Array of objects,
    // format: [{value: "name"}, {}]
    tagList() {
      return this.$store.state.tagList;
    },

    tagsSelected() {
      return this.tagsInCommon;
    },

    username() {
      return this.$store.state.user.username;
    },

    // List of videos in S3 folder with user-defined tags.  Loaded from dynamoDB table.
    videoTag() {
      return this.$store.state.videoTag;
    }
  }
}
</script>

<style>

</style>
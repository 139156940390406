Component to display thumbnails of videos in gallery format.  Supports selection of multiple
thumbnails to allow group operations such as tagging and deleting.

The array of selected items is in the Vuex state variable $store.state.selected[]

Derived from VueSelectImage.vue from https://github.com/mazipan/vue-select-image.git

Rearranging of thumbnails when the image list changes due to filtering on tags or sorting
uses the Vue.js "transition-group" component. See the example that uses transition-group name="list-complete"
at https://vuejs.org/v2/guide/transitions.html#List-Move-Transitions

<template>
  <div class="thumbGallery" >
    <VirtualScroll
      :items="imgArray"
      :itemsPerRow="rowLen"
      :firstItemShown="firstThumbShown"
      :itemListId="imgArrayId"
    >
          <!-- VirtualScroll component maps properties of an element in the
                :items array to an object it exposes as the 'item' slot
                property.  So in our case 'slotProps.item' is some of the
                properties of an element in imgArray[]. -->
          <template v-slot="slotProps">
            <thumbnail
              :source="slotProps.item"
            >
            </thumbnail>
          </template>
    </VirtualScroll>

  </div>
</template>

<script>

// The VirtualScroll component renders a list of items.  VirtualScroll.vue passes
// a set of properties to each item using the <slot> mechanism.  When there is a
// child Vue component to render each item, these slot properties ('props') are
// passed into the child.  The 'props' are an object with the following structure:
//    {
//        active: boolean,
//        index: index in the list
//        item: an object containing whatever structure this component has
//              passed for an individual item in the <RecycleScroller> :items
//              property.
//    }
// In our case each item passed into the :items prop contains a row of thumbnails,
// in the structure:
//    {
//        id: unique identifier, by default <RecycleScroller> looks for a key
//            called 'id'
//        row: an array of thumbnail objects containing however many elements
//             there will be in the row.  For a row width of 5, this is a 5-element
//             slice of imgArray[].
//    }
// So if the video URL is in the 'vid' field of imgArray[], you would access the
// this field for the first thumbnail in a row as props.item.row[0].vid
//
import VirtualScroll from './VirtualScroll.vue';
import thumbnail from './Thumbnail.vue';

export default {
  name: 'Thumbs',
  props: {

    // Array of objects with the following properties:
    //    key   [required](string) AWS key to object in S3 (i.e. path) without extension (e.g. .mp4)
    //    pic   [required] (string) Signed URL to fetch thumbnail in .jpg format
    //    vid   [required] (string) Signed URL for .mp4 video
    //    title (string) If provided allows diplay of title over image
    //    disabled (boolean) If true sets state of this thumbnail to prevent user interaction.
    imgArray: {
      type: Array,
      default: () => []
    }
  },

  data () {
    return {
      rowLen: 5,
      firstThumbShown: undefined
    }
  },

  components: {
    VirtualScroll,
    thumbnail
  },

  watch: {

    // 'imgArray.length'(newLen) {
    //   let time = performance.now();
    //   console.log('Gallery image array length now %d, elapsed %d ms', newLen, (time - this.$store.state.startTime));
    // },

    '$store.state.activeClip'(valueNow) {
      if (valueNow !== undefined) {
        this.rowLen = 1;
        this.firstThumbShown = valueNow;
      } else {
        this.rowLen = 5;
        this.firstThumbShown = undefined;
      }
    }
  },

  computed: {
    imgArrayId() {
      return this.$store.state.listDisplayed;
    }
  }
};
</script>

<style scoped>

  .thumbGallery {
    position: relative;
    padding-left: 2.5%;
    padding-top: 7em;

    /* Set to the same percentage value as padding-left after
       allowing for the 0.Xrem right margin of the last child
      .thumbGallery__item on the row. */
    padding-right: calc(2.5% - 0.5rem);

    /* The parent (class .container) is set for display: flex.  So when the
       video player is rendered, also with width 100%, this div shrinks to
       equal size, which is 50% of the viewport. */
    width: 100%;
    flex-grow: 1;
  }

    /* .thumbGallery.full { */
    /* width: 100%; */
    /* transform: translateX(-50vw); */
    /* transition: transform 1s; */
    /* flex-basis: 100vw; */
  /* } */

  /* .thumbGallery.half { */
    /* width: 50%; */
    /* left: 50%; */
    /* transition: 1s; */
    /* animation: 1s slide-right; */
    /* flex-basis: 50vw; */
  /* } */
  /* @keyframes slide-right {
    from {
      left: 0;
    }
    to {
      left: 50%;
    }
  } */

  /* @media only screen and (min-width: 1200px) {
    .thumbGallery__item {
      margin-left: 30px;
    }
  } */
</style>

<template>
  <div>
    <h1 class='heading'>{{ headingText }}</h1>
    <div class='problemMsg' v-show="failMessage">
      {{ failMessage }}
    </div>
    <div class='topMsg' v-if="topMessage">
      {{ topMessage }}
    </div>

    <div class='form' @keyup.enter="actionFn">
      <div v-if="field1Prompt">
        <input
          ref="focusOnLoad"
          class='input'
          :class="{ 'problem': field1Problem, 'happy': field1Happy }"
          :placeholder="field1Prompt"
          v-model="field1Var"
        />
        <div :class="[ helper1 ? 'helper' : 'nag', { 'problem': field1Problem, 'happy': field1Happy }]">
          {{ helper1Msg }}
        </div>
      </div>
      <div v-if="field2Prompt">
        <input
          class='input'
          :class="{ 'problem': field2Problem, 'happy': field2Happy }"
          :placeholder="field2Prompt"
          v-model="field2Var"
          type='password'
          @blur="field2Blur"
        />
        <div :class="[ helper2 ? 'helper' : 'nag', { 'problem': field2Problem, 'happy': field2Happy }]">
          {{ helper2Msg }}
        </div>
      </div>
      <div v-if="field3Prompt">
        <input
          class='input'
          :class="{ 'problem': field3Problem, 'happy': field3Happy }"
          :placeholder="field3Prompt"
          v-model="field3Var"
          type='password'
        />
        <div :class="[ helper3 ? 'helper' : 'nag', { 'problem': field3Problem, 'happy': field3Happy }]">
          {{ helper3Msg }}
        </div>
      </div>
      <button v-if="buttonLabel" @click="actionFn">{{ buttonLabel }}</button>
      <p v-if="hotLinkMsg" class='hotLink' @click="hotLinkFn">{{ hotLinkMsg }}</p>
    </div>
  </div>
</template>

<script>

export default {
  name: 'sign-up',

  props: {
    showForm: {
      type: String,
      default: ''
    }
  },

  data() {
    return {
      form: {
        username: '',
        password: '',
        attributes: {
          email: ''
        }
      },
      actionFn: undefined,
      buttonLabel: '',
      formState: 'signIn',
      failMessage: '',
      topMessage: '',
      headingText: '',
      field1Var: '',
      field1Prompt: '',
      field1Problem: false,
      field1Happy: false,
      field2Var: '',
      field2Prompt: '',
      field2Problem: false,
      field2Happy: false,
      field2Blur: undefined,
      field3Var: '',
      field3Prompt: '',
      field3Problem: false,
      field3Happy: false,
      helper1: false,
      helper1Msg: '',
      helper2: false,
      helper2Msg: '',
      helper3: false,
      helper3Msg: '',
      hotLinkMsg: '',
      hotLinkFn: undefined
    }
  },

  beforeMount() {
    this.drawForm(this.showForm ? this.showForm : 'signIn');
  },

  // mounted() {
  //   this.$nextTick(() => this.$refs.focusOnLoad.focus());
  // },

  methods: {

    checkEmail(email) {
      if (this.validEmail(email)) {
        this.form.attributes.email = email;

        // Create username from email by concatenating a '!' character.  This makes the
        // username field in the Cognito user pool unique, readable, and usable as the
        // user's folder name in S3.
        this.form.username = `${email}!`;

        return true;
      }

      this.field1Problem = true;
      return false;
    },

    checkPasswordMatch(pwd1, pwd2) {
      if (pwd2 && pwd2 === pwd1  &&  this.validPwd(pwd1)) {
        this.field2Happy = true;
        this.field3Happy = true;
        this.helper2Msg = 'Passwords match.';
        this.field3Problem = false;
        return true;

      } else if (this.field2Happy) {  // do this work only if they matched last time through
        this.field2Happy = false;
        this.field3Happy = false;
        this.helper2Msg ='Minimum 6 characters.';
      }
      return false;
    },

    checkPasswordOnBlur() {
      this.field2Problem = !this.validPwd(this.field2Var);
    },

    drawForm(formState) {

      console.log('Drawing form for %s', formState);

      // Initialize fields to defaults.
      this.field1Var = '';
      this.field1Problem = false;
      this.field1Happy = false;
      this.helper1 = false;
      this.helper1Msg = '',
      this.field2Var = '';
      this.field2Prompt = '';
      this.field2Problem = false;
      this.field2Happy = false;
      this.field2Blur = undefined;
      this.helper2 = false;
      this.helper2Msg= '',
      this.field3Var = '';
      this.field3Prompt = '';
      this.field2Problem = false;
      this.field3Happy = false;
      this.helper3 = false;
      this.helper3Msg = '';
      this.hotLinkMsg = '';

      this.formState = formState;

      switch (formState) {
        case 'signIn':
          this.actionFn = this.signIn;
          this.buttonLabel = 'Sign In';
          this.headingText = '';
          this.topMessage = '';
          this.field1Prompt = 'Email';
          this.helper1Msg = 'Please enter valid email address.';
          this.field2Prompt = 'Password';
          this.field2Blur = this.checkPasswordOnBlur;
          this.helper2 = true;
          this.helper2Msg= 'Minimum 6 characters.';
          this.hotLinkMsg = 'Forgot Password?';
          this.hotLinkFn = this.drawResetPassword;
          break;

        case 'signUpPrompt':
          this.actionFn = this.signUp;
          this.buttonLabel = 'Sign Up';
          this.headingText = 'Sign Up';
          this.field1Prompt = 'Email';
          this.helper1Msg = 'Please enter valid email address.';
          this.field2Prompt = 'Password';
          this.field2Blur = this.checkPasswordOnBlur;
          this.helper2 = true;
          this.helper2Msg= 'Minimum 6 characters.';
          this.field3Prompt = 'Re-type password';
          this.helper3Msg = 'Passwords do not match.';
          break;

        case 'signUpConfirm':
          this.actionFn = this.confirmSignUp;
          this.buttonLabel = 'Confirm Sign Up'
          this.headingText = 'Sign Up';
          this.field1Prompt = 'Verification code';
          this.helper1 = true;
          this.helper1Msg = 'A code number was sent to your email.  Enter it here.';
          this.hotLinkMsg = 'Resend code';
          this.hotLinkFn = this.resendConfirmationCode;
          break;

        case 'resetPassword':
          this.actionFn = this.resetPassword;
          this.buttonLabel = 'Send verification code'
          this.headingText = 'Reset Password';
          this.field1Prompt = 'Email';
          this.field1Var = this.form.attributes.email;
          this.helper1Msg = 'Please enter valid email address.';
          break;

        case 'resetPasswordConfirm':
          this.actionFn = this.resetPasswordSubmit;
          this.buttonLabel = 'Set new password'
          this.field1Prompt = 'Verification code';
          this.helper1 = true;
          this.helper1Msg = 'A code number was sent to your email.  Enter it here.';
          this.field2Prompt = 'New Password';
          this.field2Blur = this.checkPasswordOnBlur;
          this.helper2 = true;
          this.helper2Msg= 'Minimum 6 characters.';
          this.field3Prompt = 'Re-type password';
          this.helper3Msg = 'Passwords do not match.';
          break;

        // Success signing up or resetting password.  Display message
        // and prompt user to go to sign in.
        case 'successGoToSignin':
          this.actionFn = this.drawSignIn;
          this.buttonLabel = 'Go to sign in';
          this.field1Prompt = '';  // remove entry field
          this.failMessage = '';
          break;

        // Unrecognized state.
        default:
          console.log('Unrecognized form state.');
          break;
      }

      this.$nextTick(() => { if (this.$refs.focusOnLoad) this.$refs.focusOnLoad.focus(); });
    },

    drawSignIn() {
        this.drawForm('signIn');
    },

    drawSignUp() {
      this.failMessage = '';
      this.drawForm('signUpPrompt');
    },

    drawResetPassword() {
      this.failMessage = '';
      this.drawForm('resetPassword');
    },

    //- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
    // Action for 'signIn' state.  User has entered email and password
    // and pressed "Sign In" button.
    async signIn() {
      if (this.formState != 'signIn')  return;

      // Sets 'field1Problem' if check fails.
      this.checkEmail(this.field1Var);

      this.field2Problem = !this.validPwd(this.field2Var);
      if (this.field1Problem || this.field2Problem)  return;
      this.form.password = this.field2Var;

      this.failMessage = '';

      try {
        const user = await this.$Auth.signIn(this.form.username, this.form.password);
        this.$store.dispatch('setIsAuthenticated', true)
        this.$store.dispatch('setUser', user)
        this.$router.push('library')
      } catch (err) {
        switch (err.code) {
          case 'UserNotConfirmedException':
            {
              // Save the user's username and email in the global store so we
              // can access it to resend the authorization code if needed.
              let tempUser = {
                username: this.form.username,
                attributes: {
                  email: this.username
                }
              }
              this.$store.dispatch('setUser', tempUser);
              this.failMessage = 'You have signed up but not confirmed your email address.  Please enter code to verify your email.';
              this.drawForm('signUpConfirm');
            }
            break;
          default:
            this.failMessage = "Sorry, we couldn't find an account with this email and password.  Please try again.";
            console.log('Sign in error: ', err);
            break;
        }
      }
    },

    //- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
    // Action for 'signUpPrompt' state.  User has entered email and password
    // two times and pressed "Sign up" button.
    async signUp() {

      console.log('Formstate: ', this.formState);

      if (this.formState != 'signUpPrompt')  return;

      // Sets 'field1Problem' if check fails.
      this.checkEmail(this.field1Var);

      this.field2Problem = !this.validPwd(this.field2Var);
      if (!this.checkPasswordMatch(this.field2Var, this.field3Var)) {
        this.field3Problem = true;
      }
      if (this.field1Problem || this.field2Problem || this.field3Problem)  return;

      this.form.password = this.field2Var;

      this.failMessage = '';

      try {
        await this.$Auth.signUp(this.form);
        console.log('Signup successfully submitted.');
        this.drawForm('signUpConfirm');

      } catch (err) {
        if (err.message.includes('exists')) {
          this.failMessage = 'An account with this email already exists.  Use the Forgot Password link from the Sign In screen.'
        } else {
          this.failMessage = err.message;
        }
        console.log('error signing up...', err)
      }
    },

    //- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
    // Action for 'signUpConfirm' state.  User has entered verification code
    // and pressed "Confirm Sign Up" button.
    async confirmSignUp() {
      if (!this.form.username) {
        this.form.username = this.$store.state.user.username;
      }

      try {
        await this.$Auth.confirmSignUp(this.form.username, this.field1Var);

        // Success.  Display message and prompt user to go to sign in.
        this.topMessage = 'Account created successfully.';
        this.drawForm('successGoToSignin');

      } catch (err) {
        switch (err.code) {
          case 'CodeMismatchException':
            this.topMessage = '';
            this.failMessage = 'Sorry, we could not verify your code.\nPlease try again.';
            break;
          default:
            console.log('error signing up: ', err)
            break;

        }
      }
    },

    //- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
    // Action for 'Resend code' hotlink during 'signUpConfirm' process.
    async resendConfirmationCode() {
      if (!this.form.username) {
        this.form.username = this.$store.state.user.username;
      }
      try {
        await this.$Auth.resendSignUp(this.form.username);

        this.topMessage = 'Code was resent successfully.';
        this.failMessage = '';
      } catch (err) {
        switch (err.code) {
          case 'LimitExceededException':
            this.failMessage = 'You have exceeded the maximum number of attempts.  Please try again in 10 minutes.';
            break;
          default:
            this.failMessage = 'Sorry, there was a problem resending your code.  Please contact support@clipzoo.com.';
            console.log('error: ', err);
            break;
        }
      }
    },

    //- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
    // Action for 'resetPassword' state, first step in Reset Password sequence.
    // User inputs email and requests verification code.
    async resetPassword() {
      // if (!this.form.username) {
      //   this.form.username = this.$store.state.user.username;
      // }
      if (!this.checkEmail(this.field1Var)) return;

      try {
        await this.$Auth.forgotPassword(this.form.username)
        this.drawForm('resetPasswordConfirm');

      } catch (err) {
        switch (err.code) {
          case 'UserNotFoundException':
            this.failMessage = 'Sorry, we did not find this email.  Please sign up.';
            this.buttonLabel = 'Sign Up';
            this.actionFn = this.drawSignUp;
            break;
          case 'LimitExceededException':
            this.failMessage = 'You have exceeded the maximum number of attempts.  Please try again in 10 minutes.';
            break;
          default:
            console.log('error: ', err);
            break;
        }
      }
    },

    //- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
    // Action for 'resetPasswordConfirm' state, second step in Reset Password
    // sequence. User inputs verification code, enters password twice, and
    // clicks 'Set new password' button.
    async resetPasswordSubmit() {
      // console.log('Username is ', this.form.username);

      if (!this.validPwd(this.field2Var)) {
        this.field2Problem = true;
        return;
      }
      if (!this.checkPasswordMatch(this.field2Var, this.field3Var))  return;
      this.form.password = this.field2Var;

      try {
        await this.$Auth.forgotPasswordSubmit(this.form.username, this.field1Var, this.form.password);

        // Success.  Display message and prompt user to go to sign in.
        this.topMessage = 'Password successfully reset.';
        this.drawForm('successGoToSignin');

      } catch (err) {
        switch (err.code) {
          case 'CodeMismatchException':
            this.failMessage = 'Sorry, we could not verify your code.\nPlease try again.';
            break;
          case 'LimitExceededException':
            this.failMessage = 'You have exceeded the maximum number of attempts.  Please try again in 10 minutes.';
            break;
          default:
            console.log('error: ', err)
            break;
        }
      }
    }
  },

  watch: {

    // Validation function for field 1.
    field1Var: function() {
      if (this.formState != 'signUpPrompt'  &&  this.formState != 'signIn')  return;

      if (this.field1Problem  &&  this.validEmail(this.field1Var)) {
        this.field1Problem = false;
      }
    },

    field2Var: function() {
      this.checkPasswordMatch(this.field2Var, this.field3Var);
      if (this.field2Problem) {
         this.field2Problem = !this.validPwd(this.field2Var);
      }
    },

    field3Var: function() {
      this.checkPasswordMatch(this.field2Var, this.field3Var);
    }
  }
}
</script>

<style scoped>
</style>

<template>
  <div class='auth'>
    <div class='form-container'>
      <div>
        <sign-up
          :key="componentKey"
          :showForm="showForm"
        />
      </div>
    </div>
    <div class="link-container">
      <p>
        {{ formPrompt }}
        <span
          class='hotLink'
          @click="changeForm(formType === 'signUp' ? 'signIn' : 'signUp')">
            {{ formLink }}
        </span>
      </p>
    </div>
  </div>
</template>

<script>
import SignUp from './AuthSignUp.vue'

export default {
  name: 'authComponent',
  data() {
    return {
      formType: 'signIn',
      formPrompt: 'New to ClipZoo?',
      formLink: 'Sign Up',
      showForm: '',
      componentKey: 0
    }
  },
  components: {
    SignUp
  },
  methods: {
    changeForm(formType) {
      console.log('changeForm fn, formtype:', formType);
      this.formType = formType;
      switch (formType) {

        case 'signIn':
          this.showForm = 'signIn';
          this.formPrompt = 'New to ClipZoo?';
          this.formLink = 'Sign Up';
          break;

        case 'signUp':
          this.showForm = 'signUpPrompt';
          this.formPrompt = 'Already have an account?';
          this.formLink= 'Sign In';
          break;
      }
      ++this.componentKey;
    }
  }
}
</script>

<style scoped>

.auth {
  display: flex;
  flex-direction: column;
  margin-top: 3.5rem;  /* start below header */
  position: fixed;
  width: 100%;
}

.link-container p {
  text-align: center;
}

.form-container {
  width: 20rem;
  margin: 3rem auto 0;
  padding: 1rem 0;
  font-size: 0.75rem;
  border-radius: 0.5rem;
  /* border: 2px solid var(--background-dark); */
  box-shadow: 1px 1px 5px rgba(0, 0, 0, .3);
  background-color: var(--background-light);
}

/* The "deep selector" (:deep()) causes these CSS rules to apply to child component
   SignUp and its children. */
.form-container :deep() button {
    height: 2.5rem;
    width: 16rem;
    border-radius: 1.25rem;
    margin: 1rem 0;
    text-transform: none;
    font-size: 1rem;
    letter-spacing: normal;
}

.form-container :deep() input {
  height: 2.5rem;
  width: 16rem;
  font-size: 1rem;
  text-align: left;
  padding-left: 1rem;
  border: 1px solid var(--background-medium);
  border-radius: 0.5rem;
  outline: none;
  margin-top: 0.5rem;
  /* background-color: green; */
}

.form-container :deep() input.happy {
  border: 2px solid green;
  background-color: rgba(0, 128, 0, .1);
  color: green;
}

.form-container :deep() input.problem {
  border: 2px solid red;
  background-color: rgba(128, 0, 0, .1);
  color: red;
}

.form-container :deep() .form {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* background-color: hotpink; */
}

.form-container :deep() .heading {
  margin: 0 0 0.5rem 0;
  text-align: center;
  font-size: 1.5rem;
  font-weight: 600;
}

/* Message at top of form, inserted when a problem occurs. */
.form-container :deep() .problemMsg {
  background-color: white;
  color: red;
  padding: 0.5rem;
  margin: 0 1rem;
  text-align: center;
  white-space: pre-wrap;
  font-size: .75rem;
}

.form-container :deep() .topMsg {
  padding: 1rem;
  text-align: center;
  font-size: 1rem;
}

/* Message below form input field.  The .nag occupies space but visible only on error. */
.form-container :deep() .helper, .form-container :deep() .nag {
  width: 16rem;
  padding: 0.25rem 1rem;
  font-size: .75rem;
  color: var(--background-dark);
}

.form-container :deep() .helper.happy {
  color: green;
}

.form-container :deep() .nag {
  visibility: hidden;
}

.form-container :deep() .helper.problem, .form-container :deep() .nag.problem {
  color: red;
  visibility: visible;
}

.auth :deep() .hotLink {
  margin: 0 0 0 0.3rem;
  text-align: center;
  cursor: pointer;
  color: var(--action-color);
}

.auth :deep() .hotLink:hover {
  opacity: .7;
  text-decoration: underline;
}
</style>

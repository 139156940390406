Component capable of displaying a video preview in a parent rectangle.  When
the parent creates this component, it renders an HTML video tag and plays the
video in the URL provided in the "vidsrc" property.

The 'video' element is a native HTML element.  Note the template assigns the
clip's passed-in unique identifier to the 'id' property so we can access it
in the event callback.  The prop is named 'id' because 'key' is a Vue
reserved 'special' attribute.

<template>
    <video
      :src="vidsrc"
      :id="clipKey"
      playsinline
      muted
      @canplaythrough="onPlaybackReady"
    >
    </video>
</template>

<script>

// Note:  if you want to use the <source :src="url" type="video/mp4"> sub-tag
//        under the <video> tag in the template, add :key="url" to the <video>
//        tag to make Vue happy.  Causes Vue to detect a change in the element.
//        <source ref="source" :src="clipUrl" type="video/mp4">

export default {
  name: 'Preview',

  props: {
    vidsrc: {
      type: String,
      default: ''
    },
    clipKey: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    }
  },

  data () {
    return {
      player: undefined,
      previewActive: false
    }
  },

  methods: {

    // Handle 'canplaythrough' event from <video> tag.
    onPlaybackReady(e) {
      // console.log('Playback is ready', e.target.id);

      // Have never seen this happen, but if the 'canplaythrough' callback
      // somehow gets routed to the wrong player, it would have some wacky
      // side effects, so detect it here.
      if (e.target.id != this.clipKey) {
        console.log('Whoops, target != this', e.target.id, this.clipKey);
        return;
      }

      if (e.target) {
        // User may have moved to another preview before playback became
        // ready for this clip.  If that is the case Vue has already
        // unmounted this component, so shut this player down without
        // touching stale data.
        if (!this.previewActive) {
          e.target.removeAttribute('src');
          // console.log('Playback ready for %s but component is unmounted', this.clipKey);
        } else if (this.player === undefined) {
          this.player = e.target;

          // Start playback.  Note this causes another 'canplaythrough' event.
          this.player.play();
          // console.log('Started play clip %s', this.clipKey);
        } // else {
        //   console.log('CanPlayThrough event, but already playing.');
        // }
      }  else console.log('Playback ready for %s but event has no player', this.clipKey);
    }
  },

  mounted() {

    this.previewActive = true;

    //- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
    // Create the player and get playback started ASAP.
    // if (this.vidsrc) {
    //   // console.log('Creating player for clip %s', this.clipKey);

    //   if (this.player === undefined) {
    //     console.log('Player not assigned for ', this.clipKey);
    //     return;
    //   }
    //   if (!this.playReady) {
    //     console.log('Playback not ready', this.clipKey);
    //     return;
    //   }

    //   // console.log(this.clipKey, ' Play');
    //   this.player.play().catch((e) => {
    //     console.log('Error from play command: ', e);
    //   });
    // }
  },

  beforeUnmount() {

      this.previewActive = false;

      //- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
      // Exiting the player.  Stop playback and any loading in progress, and
      // display the still image.
      if (this.player) {

        // Doubtful if this helps, but doesn't hurt.
        this.player.pause();

        // In an old (2010) post on Stack Overflow, the wisdom was that
        // clearing the 'src' field removes the cached media from memory.
        // In testing, however, after setting .src = '', the .src field
        // ends up with the site URL...?? So .removeAttribute() appears
        // to do the trick.
        // https://stackoverflow.com/questions/3258587/how-to-properly-unload-destroy-a-video-element
        // this.player.src = '';
        this.player.removeAttribute('src');

        // Supposedly causes loading in progress to abort.
        this.player.load();
        // console.log('Leaving player for clip %s, src is', this.clipKey, this.player.src);
      } // else {
      //   console.log('Unmounting component for %s but player is not set', this.clipKey);
      // }
  }
}
</script>

<style scoped>

/* Fill the parent container. */
video {
  position: absolute;
  width: 100%;
  max-height: 100%;
}
</style>

import { createApp } from 'vue'
import { createRouter, createWebHistory } from 'vue-router'

// configureCompat({ WATCH_ARRAY: false });
// export default {
//   compatConfig: {
//     WATCH_ARRAY: false
//   }
// }

import Auth from '@aws-amplify/auth' // eslint-disable-line no-unused-vars

// Vue components
import App from './components/App.vue'
import AuthComponent from './components/Auth.vue'
import Home from './components/Home.vue'
// import Protected from './components/Protected.vue'
import Library from './components/Library.vue'

// Vuex store
import store from './store'

import modal from './modal'

// global fonts and styles
import '@fontsource/roboto'
require('./assets/main.css')

const app = createApp(App);

//- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
// Authentication

// amplify configuration
// Thanks to alt146's answer on Stack Overflow, this technique for using
// the Amplify "modular imports" and NOT importing 'AmplifyPlugin' is a HUGE
// decrease in bundle size as it seems to avoid an unnecessary complete copy
// of the aws-sdk from being included.  The aws-sdk portion of the vendor
// chunk went from 4.49MB to 603KB and the AWS Amplify portion ends up at
// 642K, less than half what it was before.
// https://stackoverflow.com/questions/53925177/aws-amplify-modular-imports-with-vue

// export interface AuthOptions {
//   userPoolId?: string;
//   userPoolWebClientId?: string;
//   identityPoolId?: string;
//   region?: string;
//   mandatorySignIn?: boolean;
//   cookieStorage?: ICookieStorageData;
//   oauth?: OAuthOpts;
//   refreshHandlers?: object;
//   storage?: ICognitoStorage;
//   authenticationFlowType?: string;
//   identityPoolRegion?: string;
//   clientMetadata?: any;
// }

Auth.configure({
  region: process.env.VUE_APP_AWS_REGION,
  userPoolId: process.env.VUE_APP_COGNITO_USERPOOL_ID,
  userPoolWebClientId: process.env.VUE_APP_COGNITO_CLIENT_ID,
  oauth: process.env.VUE_APP_COGNITO_OAUTH
});

app.config.globalProperties.$Auth = Auth // <- This line is important

//- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
// Router

const routes = [
  { path: '/', component: AuthComponent },
  // { path: '/protected', component: Protected, meta: { requiresAuth: true} },
  { path: '/home', component: Home },
  { path: '/library', component: Library, meta: { requiresAuth: true} },
]

// router definition
const router = createRouter({
  history: createWebHistory(),
  routes: routes
})

// implement protected routes for only signed in users
router.beforeResolve((to, _, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    Auth.currentAuthenticatedUser().then((data) => {
      if (data && data.signInUserSession) {
        next()
      }
    }).catch(() => {
      console.log('You are trying to access a protected route. Please sign in.')
      next({
        path: '/',
        query: {
          redirect: to.fullPath,
        }
      });
    });
  } else next();
})

app.use(router);
app.use(store);
app.use(modal);

// For Vue Performance Devtool browser extension.
app.config.devtools = true
app.config.performance = true


//- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
// Globally available helper methods.

app.mixin({
  methods: {
    validEmail(email) {
      var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },

    validPwd(password) {
      return password.length >= 6;
    }
  }
})

app.mount('#app');

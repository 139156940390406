// STRUCTURES OF OBJECTS IN THIS APP
//
// videoTag[] {
//    id:       number
//    key:      string
//    sk:       string (original sk field from DynamoDB table)
//    tags:     [] array of strings (in DynamoDB, a set)
//    thumbUrl: string
//    vidUrl:   string
// }
//
//  thumbsAll[] {
//    id:       number
//    key:      string (AWS S3 key)
//    pic:      string (signed URL)
//    vid:      string (signed URL)
//    title:    string
//  }
//
//  Array of tags (strings) in the format required by Tags.vue, which is an array
//  of objects
//  tagList: [
//    {
//      value: "name"
//    }, {}
//  ]

import { createStore } from 'vuex'
import { S3Client, GetObjectCommand } from '@aws-sdk/client-s3';
import { DynamoDBClient, QueryCommand, UpdateItemCommand } from '@aws-sdk/client-dynamodb';
import { marshall, unmarshall } from '@aws-sdk/util-dynamodb';
import { getSignedUrl } from '@aws-sdk/s3-request-presigner';
import axios from 'axios';


const worker = new Worker('./worker.js', { type: 'module' });
worker.onmessage = e => {
  // console.log(e.data.type);
  // console.log(e.data.payload);

  // worker.js sets e.data.type to 'setVideoTagUrls'
  store.commit(e.data.type, e.data.payload);
};

// Actions are asynchronous.  They can do asynchronous operations
// and commit mutations.  They cannot mutate the state.  Call with '.dispatch'.
const actions = {

  createDynamoDBClient ({ commit }, creds ) {
    const ddb = new DynamoDBClient({
      apiVersion: '2012-08-10',
      region: process.env.VUE_APP_AWS_REGION,
      credentials: creds
    });
    commit('setDynamoDB', ddb);
  },

  createS3Client ({ commit }, creds ) {
    this.s3params = {
      signatureVersion: 'v4',
      region: process.env.VUE_APP_AWS_REGION,
      credentials: creds,
      params: {Bucket: process.env.VUE_APP_BUCKET}
    };

    const s3Client = new S3Client({
      // apiVersion: '2006-03-01',
      signatureVersion: 'v4',
      region: process.env.VUE_APP_AWS_REGION,
      credentials: creds,
      // params: {Bucket: process.env.VUE_APP_BUCKET}
    });
    commit('setS3', s3Client);
  },

  async createSignedUrlsWorker({ commit, state }, list) {  // eslint-disable-line no-unused-vars

    worker.postMessage({ msg: 'createUrls', s3config: this.s3params, folder: state.userFolder, list: list });
  },

  // Create signed URLs for the thumbnail and video resources in
  // videoTag[].  If 'qty' is specified, creates URLs only for the
  // first 'qty' elements of videoTag[].  If not specified, creates
  // URLs for all elements.
  async createSignedUrls({ commit, state }, qty) {

    if (!qty) {
      qty = state.videoTag.length;
    }

    for (let ext = '.jpg', prop = 'thumbUrl'; ; ) {

      // let t0 = performance.now();
      let i = 0;
      for (const [idx, obj] of state.videoTag.entries()) {

        // Skip the work if the field is already populated e.g. from a previous
        // call to this function with a limited 'qty'.
        if (!state.videoTag[idx][prop]) {

          const getCmd = new GetObjectCommand({
            Bucket: process.env.VUE_APP_BUCKET,
            Key: `${state.userFolder}${obj.key}${ext}`
          });
          try {
            // 'expiresIn' is expressed in seconds
            const url = await getSignedUrl(state.clientS3, getCmd, { expiresIn: 120 * 60 });
            commit('setVideoTagUrl', { idx, prop, url });

          } catch (err) {
            console.log('Error getting signed URL ', err);
          }
        }

        if (++i >= qty)  break;
      }

      // let t1 = performance.now();
      // console.log('%s batch created %d in %s ms, (%s ms each)', prop, qty, (t1-t0), ((t1-t0)/qty).toFixed(2));
      if (ext === '.jpg') {
         ext = '.mp4';
         prop = 'vidUrl';
         continue;
      }

      break;
    }
  },

  // Vuex actions return a promise.  When a caller does await dispatch for
  // an action it appears the caller gets this action's return value as
  // the resolved value of the promise.
  async deleteClips({ dispatch, commit, state }, list) {   // eslint-disable-line no-unused-vars

    const date = new Date().toISOString().substring(0, 10);

    for (const d of list) {
      const vt = state.videoTag.find(v => v.key.includes(d.key));
      if (vt === undefined) { continue; }

      try {
        const success = await dispatch('updateVideoTag',
          { sk: vt.sk,
            deleteDate: date
          });

          if (!success)  return false;
      } catch (err) {

        console.log('error: ', err);
        return false;
       }
    }

    commit('removeFromVideoTag', list);
    return true;
  },

  async fetchVideoTag ({ commit, state }) {

    let params = {
      TableName: 'VideoTag',
      KeyConditionExpression: 'pk = :user and begins_with (sk, :v)',
      ExpressionAttributeValues: marshall({
        ':user': state.user.username,
        ':v': 'V#'
      })
    };

    try {
      const response = await state.clientDynamoDB.send(new QueryCommand(params));
      // console.log("From DynamoDB:", response.Items.map(item => unmarshall(item)));

      commit('populateVideoTag', response.Items);

    } catch (err) { console.log('Error fetching videoTag[] from DynamoDB: ', err); }
  },


  // Retrieve temporary credentials from the Clipzoo REST API.  This is a
  // serverless Lambda function that returns limited credentials that allow access
  // only to the user's own private folder on the Clipzoo user S3 bucket.
  // This function can also be used to refresh expired redentials.
  async setCredentials ({ dispatch, commit, state }, awsAuth) {

    const apiUrl = process.env.VUE_APP_API_CREDENTIALS;

    try {
      // According to Amplify docs this call automatically refreshes the
      // idToken and accessToken.
      const session = await awsAuth.currentSession();

//debug error doing reload after token expires
if (state.user.username === undefined) {
  console.log('User name undefined, session is: ', session);
}
      commit('setAuthToken', session.idToken.jwtToken);

      // This sets the Authorization header for ALL axios calls.  If you want to add it to
      // individual calls, you can include a parameter that defines the header in this format:
      // {"Authorization" : `Bearer ${session.idToken.jwtToken}`}
      axios.defaults.headers.common['Authorization'] = `Bearer ${session.idToken.jwtToken}`;

      // const response = await axios.get(apiUrl, { headers:state.authHdr });
      const response = await axios.get(apiUrl);

      const stsToken = response.data.token;

      const creds = {
          accessKeyId: stsToken.AccessKeyId,
          secretAccessKey: stsToken.SecretAccessKey,
          sessionToken: stsToken.SessionToken
      };

      dispatch('createS3Client', creds);
      dispatch('createDynamoDBClient', creds);

      //////////////////////////// test user pool authorizer
      // const testUrl = process.env.VUE_APP_API_S3_SET_SPACE_USED;

      // let response1 = await axios.put(testUrl, {});
      // console.log(response1.data);

      // response1 = await axios.get(testUrl);
      // console.log(response1.data);
      //////////////////////////// test user pool authorizer - end


    }  catch (err) { console.log('error: ', err); }
  },

  setIsAuthenticated ({ commit }, isAuthenticated) {
    commit('isAuthenticated', isAuthenticated);
  },

  setUser ({ commit }, user) {
    commit('setUser', user);
  },

  setSearchActive ({ commit }, value) {
    commit('changeSearchActive', false === value ? false : true);
  },

  // Update a record in the DynamoDB videoTag database.  Parameter
  // 'updateDetails' is an object:
  //      { sk:  'V#something',   (sort key of record)
  //        tags: []              (array of tags for this record)
  //          OR
  //        deleteDate: '2021-01-01' (date to set in record's 'deleted' field)
  //      }
  async updateVideoTag ({ commit, state }, updateDetails) {    // eslint-disable-line no-unused-vars

    // Parameter object needs to be in format required by DynamoDB.  Use
    // marshall() utility function for objects.
    let params = {
      TableName: 'VideoTag',
      Key: marshall({
        'pk': state.user.username,
        'sk': updateDetails.sk
      })
    };
    // console.log('On entry, params:', params);

    if ('tags' in updateDetails)
    {
      // Adding new tag.
      if (updateDetails.tags.length > 0) {
        params.UpdateExpression = 'set tags = :t';
        params.ExpressionAttributeValues = marshall({
            ':t': new Set(updateDetails.tags)
          });
        // console.log('Set to write to DynamoDB:', params.ExpressionAttributeValues[':t']);

      // tags[] is an empty array, remove the tag field from this record in the database.
      } else {
        params.UpdateExpression = 'remove tags';
      }
    } else if ('deleteDate' in updateDetails) {
      params.UpdateExpression = 'set deleted = :d';
      params.ExpressionAttributeValues = marshall({
          ':d': updateDetails.deleteDate
        });

    } else {
      console.log('updateVideoTag() called with missing parameter.');
      return false;
    }

    // console.log('After marshall:', params);

    try {
      await state.clientDynamoDB.send(new UpdateItemCommand(params));
      return true;
    } catch (err) { console.log('error: ', err); }

    return false;
  }
}

// Mutations must be synchronous.  They mutate the state directly.
// Call with '.commit'.  When adding new properties to an object,
// replace the object with a fresh one for the change to be reactive.
// Note mutations expect two arguments, state and payload.  For multiple
// arguments pass them wrapped in an object.
const mutations = {

  firstImageCountInit(state, threshold) {
    state.firstImagesLoaded = false;
    this.firstImageThreshold = threshold;
    this.firstImageCount = 0;
  },

  firstImageCountBump(state) {
    if (this.firstImageCount < this.firstImageThreshold) {
      if (++this.firstImageCount === this.firstImageThreshold) {
        // setTimeout(() => {state.firstImagesLoaded = true;}, 2000);
        state.firstImagesLoaded = true;
      }
    }
  },

  firstRenderCountInit(state, threshold) {
    state.firstComponentsRendered = false;
    this.firstComponentThreshold = threshold;
    this.firstComponentCount = 0;
  },

  firstRenderCountBump(state) {
    if (this.firstComponentCount < this.firstComponentThreshold) {
      if (++this.firstComponentCount === this.firstComponentThreshold) {
        state.firstComponentsRendered = true;
        console.log('Rendering complete, %s seconds elapsed.', ((performance.now() - state.startTime)/1000).toFixed(2));
      }
    }
  },

  isAuthenticated(state, isAuthenticated) {
    state.isAuthenticated = isAuthenticated;
  },

  setActiveClip(state, key) {
    const v = state.videoTag.find(item => item.key === key);
    if (v !== undefined) {
      state.activeClip = key;
      state.activeClipRecent = key;
      state.activeClipUrl = v.vidUrl;
      console.log('active clip:', key);
    }
  },

  setAuthToken(state, jwt) {
    state.cognitoJwt = jwt;
  },

  setDynamoDB(state, ddb) {
    state.clientDynamoDB = ddb;
  },

  setS3(state, s3) {
    state.clientS3 = s3;
  },

  setListDisplayed(state, listId) {
    state.listDisplayed = listId;
  },

  // Set the 'previewActive' state variable to the provided 'key'.
  // Call with id set to 'undefined' to indicate that a preview is no
  // longer active.  Debounces calls with a 250ms delay to prevent
  // browser thrashing as user moves the pointer across a gallery
  // of many thumbnails.
  setPreviewActive(state, key) {

    clearTimeout(this.debouncePreviewActive);
    this.debouncePreviewActive = setTimeout(() => {
      this.debouncePreviewActive = undefined;

      state.previewActive = key;
      // console.log('After debounce, previewActive set to ', state.previewActive);
    }, 250);
  },

  setUser(state, user) {

    // If caller is not providing a username, clear the active user.
    if (!user.username) {
      this.commit('clearUser');
      return;
    }

    state.user = user;

    // The user's S3 foldername is assigned when the user first signs
    // up for ClipZoo by a Lambda function that runs off the Cognito
    // User Pool "post confirmation" trigger.
    let folder = user.attributes['custom:folder'];

    // As a fallback if the folder was not assigned as expected, make the
    // folder from the Cognito username, which is the user's email address
    // used for signup with a '!' character appended.  The user's folder on
    // S3 is accessed through signed URLs and '!' is not allowed in URLs, so
    // so make the user's folder name by truncating the '!' character.
    if (folder === undefined)  folder = user.username.slice(0, -1);

    console.log('Folder is ', folder);

    // Delete this line when you move the videos to the newly named folder.
    // state.userFolder = state.user.username.slice(0, -1) + '/';

    // Append the slash now to make it easier to use this to build
    // a path when reading and writing files from this folder.
    state.userFolder = folder + '/';
  },

  clearUser(state) {
    state.user = {};
    state.userFolder = '';
    state.isAuthenticated = false;
  },

  // Replace the 'tags' field of one element in videoTag[]. The 'idx'
  // parameter is an index into videoTag[] e.g. from .findIndex(),
  // which may be different from the videoTag[].id property.
  setVideoTagTags(state, { idx, tags }) {
    state.videoTag[idx].tags.length = 0;
    state.videoTag[idx].tags.push(...tags);
  },

  setVideoTagUrl(state, { idx, prop, url }) {
    state.videoTag[idx][prop] = url;
    //obj[`${prop}`]
  },

  // Sets many signed URL fields in videoTag[] from provided list[].
  setVideoTagUrls(state, list) {

    list.forEach(obj => {
      const i = state.videoTag.findIndex(v => v.key === obj.key);
      if (i >= 0) {
        state.videoTag[i].thumbUrl  = obj.tUrl;
        state.videoTag[i].vidUrl    = obj.vUrl;
      }
    });

    state.urlsCreated = true;
  },

  setStartTime(state) {
    state.startTime = performance.now();
  },

  setSelectActive(state, value) {
    state.selectActive = (false === value) ? false : true;
  },

  setTagEditActive(state, value) {
    state.tagEditActive = (false === value) ? false : true;
  },

  clearActiveClip(state) {
    state.activeClip = undefined;
    state.activeClipUrl = '';
  },

  clearSelected(state) {
    state.selected.splice(0);
  },

  clearThumbsFiltered(state) {
    state.thumbsFiltered.splice(0);
  },

  changeSearchActive(state, value) {
    state.searchActive = value;
  },

  populateVideoTag(state, items) {

    // console.log("From DynamoDB:", items.map(item => unmarshall(item)));

    state.storageUsed = 0;

    state.videoTag.length = 0;

    state.videoTag = items.flatMap((item) => {
      const obj = unmarshall(item);

      // Exclude deleted clips from videoTag[].
      if (obj.deleted)  return [];

      // Calculate the total S3 storage space used.
      state.storageUsed += obj.size;

      // Strip the sort prefix (V#) and extension off the key to faciliate
      // generating signed URLs and locating this record to access tags.
      return ([{ //'id'  : i++,
                 'key' : obj.sk.replace('V#', '').replace('.mp4', ''),
                 'sk'  : obj.sk,
                 'tags': obj.tags === undefined ? [] : [...obj.tags],
                 'thumbUrl': '',
                 'vidUrl': ''
              }]);
    });
    // console.log("videoTag2:", state.videoTag);
    // console.log("Total items %d, size used: %d MB", state.videoTag.length, state.storageUsed / (1024 * 1024));
  },

  // Map an array of tags (strings) to tagList[], which is an array
  // of objects in the format required by Tags.vue
  populateTagList(state, tags) {

    state.tagList = tags.map(t => ({ 'value': t }));  // format required by Tags.vue
  },

  refreshThumbsAll(state, list) {

    const newEntries = list.map(v => (
      { //id: v.id,
        key: v.key,
        pic: v.thumbUrl,
        vid: v.vidUrl,
        title: ''
      }
    ));

    state.thumbsAll.length = 0;
    state.thumbsAll.push(...newEntries);
  },

  // populateThumbsAll(state, list) {
  //   state.thumbsAll.length = 0;
  //   this.commit('appendThumbsAll', list);
  // },

  // appendThumbsAll(state, list) {
  //   const newEntries = list.map(v => (
  //     { id: v.id,
  //       key: v.key,
  //       pic: v.thumbUrl,
  //       vid: v.vidUrl,
  //       title: ''
  //     }
  //   ));
  //   state.thumbsAll.push(...newEntries);
  //   console.log('appended %d new entries', newEntries.length, state.thumbsAll.map(t => ({'id': t.id, 'datapic': t.datapic})));
  //   // if (state.thumbsAll.length > 100) {  //debugx
  //   //   console.log('thumbsAll[] completely populated, elapsed %d ms', (performance.now() - state.startTime));
  //   // }
  // },

  // lazyLoadThumbsAll(state, id) {
  //   const i = state.thumbsAll.findIndex(img => img.id === id);
  //   if (i >= 0) {
  //     state.thumbsAll[i].pic = state.thumbsAll[i].datapic;
  //   }
  // },

  populateThumbsFiltered(state, list) {

    state.thumbsFiltered.length = 0;
    state.thumbsFiltered.push(...list);
  },

  pushSelected(state, item) {
    state.selected.push(item);
  },

  // 'tag' is a string...
  pushTagList(state, tag) {

    state.tagList.push({ 'value' : tag });  // format of tagList[] required by Tags.vue
    state.tagList.sort((a, b) => (a.value < b.value) ? -1 : 1);
  },

  pushVideoTag(state, item) {
    state.videoTag.push(item);
  },

  removeFromSelected(state, key) {
    state.selected = state.selected.filter(item => {
      return key !== item.key;
    });
  },

  removeFromTagList(state, idx) {

    state.tagList.splice(idx, 1);
  },

  removeFromVideoTag(state, list) {
    console.log('delete clips:', list);

    const ditch = new Set(list.map(l => l.key));
    console.log('Set to delete, videoTag before', ditch, state.videoTag.map(v => ({key: v.key})));

    // Remove the clips from videoTag[].
    state.videoTag = state.videoTag.filter(v => !ditch.has(v.key));
    console.log('VideoTag after', state.videoTag.map(v => ({key: v.key})));

    // Also remove them from thumbsFiltered[] and thumbsAll[] which updates
    // the display in the thumbnail gallery.
    if (state.thumbsFiltered.length) {
      state.thumbsFiltered = state.thumbsFiltered.filter(t => !ditch.has(t.key));
    }
    this.commit('refreshThumbsAll', state.videoTag);
  }
}

const store = createStore({
  state: {
    activeClip: undefined,     // 'key' for clip user is working with
    activeClipRecent: undefined,
    activeClipUrl: '',
    clientS3: {},
    clientDynamoDB: {},
    cognitoJwt: '',
    firstComponentsRendered: false,
    firstImagesLoaded: true,
    isAuthenticated: false,
    listDisplayed: -1,    // which of several lists are being shown in the thumbnail gallery
    user: {},
    userFolder: '',
    previewActive: {
      type: Number,
      default: undefined
    },
    selected: [],         // array of selected thumbnails, maintained in ThumbGallery.vue
    selectActive: false,  // toggled on by "select" button in header, off when action bar is closed
    searchActive: false,  // true when search bar is open
    startTime: 0,
    storageUsed: 0,
    tagEditActive: false,
    tagList: [],          // all user-defined tags for this user
                          // Array of objects, format: [{value: "name"}, {}]
    thumbsAll: [],        // signed URLs for thumbnail keys (.jpgs) for all user's videos in S3
    thumbsFiltered: [],   // filtered, sorted from thumbsAll
    urlsCreated: false,
    videoTag: []          // list of videos in S3 folder with user-defined tags.  Loaded from dynamoDB table.
  },
  actions,
  mutations,
  data () {
    return {
      debouncePreviewActive: undefined,
      firstImageCount: 0,
      firstImageThreshold: 0,
      firstComponentThreshold: 0,
      firstComponentCount: 0,
      s3params: {}
    }
  }

})

// Returns a function that, as long as it continues to be invoked, will not
// be triggered. The function will be called after it stops being called for
// msWait milliseconds. If 'leading' is passed, trigger the function on the
// leading edge instead of the trailing.
// const debounce = (func, msWait, leading=false) => {
//   let timeout;
//   // console.log('In debounce fn');
//   return function (...args) {
//     const later = () => {
//       timeout = null;
//       if (!leading) func.apply(this, args);
//     };
//     const callNow = leading && !timeout;
//     clearTimeout(timeout);
//     timeout = setTimeout(later, msWait);
//     if (callNow) func.apply(this, args);
//   }
// };

// const debouncedSetPreviewActive = debounce((state, id) => {
//   state.previewActive = (id < 0) ? undefined : id;
//   console.log('previewActive set to ', state.previewActive);
// }, 250);


export default store
VirtualScroll.vue
Created 7/15/21
Revised 8/4/21

High performance virtual scroll component.  Displays list in a uniform grid.
The parent passes the list of items to display in the 'items' prop, and specifies
the number of items in a row with the 'itemsPerRow' prop.

To make the grid, a computed property called 'renderedRows' slices the 'items'
list into an array of objects, basically a 2-dimensional array:
  [{ id: xx, row: [ item[n++], item[n++]...] },
   { id: yy, row: [ item[n++], item[n++]...] }, ...]

This component is based on the following examples:
  - A codepen by 'zupkode' https://codepen.io/zupkode/pen/oNgaqLv
    This implements a virtual scroller in Vue JS with fixed-size rows and
    a known number of elements in a list.  This is well suited to ClipZoo.
    Zupkode's pen is based on a very good blog entry:
  - https://dev.to/adamklein/build-your-own-virtual-scroll-part-i-11ib
    that explains the concept implemented here.
  - This module uses an Intersection Observer directive instead of
    a scroll eventListener to detect when the user scrolls.  This directive
    is in v-observe.js and is based on
    https://blog.parametricstudios.com/posts/vue-directive-intersection-observer/


<template>
  <div class="viewport" ref="viewport" :style="viewportStyle">
    <div class="scrollArea" ref="scrollArea" :style="scrollAreaStyle">
      <div class="row"
        :class="{ hide: index < hidePaddingRows }"
        v-observe="iObserver"
        v-for="(r, index) in renderedRows"
        ref="row"
        :key="r.rowKey"
      >
        <div class="column"
          v-for="i in r.row"
          :key="i.item.itemKey"
          :style="cssColumnWidth"
        >
          <span class="item">
            <slot
              :item="i.item"
            ></slot>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

// Note: if you want to be able to access individual items, set the
// ref for each item to an unique value in the template like this:
// Vue 3:
//    In template:
//      <div class="row"
//        :ref="setRowRef" >
//    In data:
//      rowRefs[],
//    In methods:
//      setRowRef(el) {
//        if (el) {
//          this.rowRefs.push(el);
//        }
//      },
//    In beforeUpdate():
//      this.rowRefs = [];
//
//    Then you access elements in the rowRefs[] array in the standard way:
//      this.rowRefs[rowIdx].scrollIntoView(true);
//
// Vue 2:
//    <span class="item"
//     :ref="`item${i.key}`" >
// Then in the code you can access an item with
//    this.$refs[`item${i}`]
// For example:  this.$refs[`item${i}`].scrollIntoView(true);
//
// Another note:
//    Updating on vertical resize was overly complicated because detecting
//    vertical resize requires an eventListener on the 'resize' event, which
//    in turn needs throttling.  For future reference here is code for a
//    throttled handler:
//
//    handleResize() {
//      if (this.resizeBlocked === undefined) {  // in data(): resizeBlocked : undefined
//        this.resizeBlocked = setTimeout(() => { this.resizeBlocked = undefined }, 100);
//        // Calculate new padding value here if the resize is more than a row height
//       }
//    }
//    In mounted(), .removeEventListener in beforeUnmount()
//        window.addEventListener('resize', this.handleResize);


import vObserve from "./v-observe";

// https://dev.to/adamklein/build-your-own-virtual-scroll-part-i-11ib

export default {
  name: "VirtualScroll",
  directives: {
    observe: vObserve
  },
  props: {
    items: Array,
    itemsPerRow: Number,
    firstItemShown: {
      type: [Number, String],
      default: undefined
    },

    // Parent changes this prop to signal 'items[]' is completely different
    // and display should be reinitialized.
    itemListId: {
      type: [Number, String],
      default: undefined
    }
  },
  emits: [ 'resize' ],
  data() {
    return {

      clientWidth: 0,  // used for decision to $emit change in width

      iObserver: undefined,  // Intersection Observer, for scrolling
      rObserver: undefined,  // Resize Observer
      resizing: undefined,   // debounce timer during resizing
      settling: undefined,
      scrollDelay: undefined,   // debounce timer for intersection observer

      // Pixels from the very top of the virtual list to the top of the
      // browser window.  Used only by the Intersection Observer handler.
      scrollTop: 0,
      lastScrollY: 0,

      // Zero until first render.
      rowHeight: 0,

      // This is the number of rows we render after the starting index.
      // If the array has a total 10000 rows, we want to render rows from say
      // index 1049 to 1069.  Rendered row count is the number of rows (20)
      // and starting index is 1049.  Needs to start non-zero to render first
      // time, start with default row padding.
      // renderedRowCount: 20,
      renderedRowCount: 1,
      startRowIdx: 0,

      firstItemPrevious: 0,
      priorityFirstRow: undefined,
      priorityLastRow:  undefined,

      // Padding at the top and bottom so that the items transition smoothly:
      // extra rows just above and below the browser window.
      // Allow for one full screen of rows as padding, up to 20 maximum.  Property
      // 'renderedRowCount' adds 'rowPaddingTop' and 'rowPaddingBottom'.
      hidePaddingRows: 0,
      rowPaddingTop: 5,
      rowPaddingBottom: 5
    };
  },

  watch: {

    itemListId(newVal, oldVal) {
      // console.log('Parent changed list from %d to %d', oldVal, newVal);
      if (newVal !== oldVal)  this.setStartRow(0);
    },

    // List length has changed.  Could be parent has switched out the item list,
    // or there could be items added or deleted.  If the parent switches out the
    // entire list, it probably changed the 'itemListId' prop and the watch on
    // this prop does this same work.  So a watch on this 'length' property is
    // sort of belt-and-suspenders and redundant, but it is here to catch unforseen
    // changes to the item list.
    'items.length'(newLen, oldLen) {
      if (oldLen - newLen > 2) {
        // console.log('List id now %d, length changed a boatload from %d to %d', this.itemListId, oldLen, newLen);
        this.setStartRow(0);
      }
    },

    // When the parent changes 'itemsPerRow', the work is the same that
    // the Resize Observer handler does but without the debounce delay.
    // First, set a new start row which, together with the new value for
    // 'itemsPerRow', computes a new 'renderedRows[]' list.
    // Next, delay for changes to render then conform 'rowHeight' to the
    // rendered adjusted height.  Finally, delay for the height changes
    // to render the new container height and scroll to the new position
    // the top left item occupies in the reshaped list.
    itemsPerRow(newVal, oldVal) {

      // When the top visible row is less than 'rowPaddingTop', calculate
      // this row based on the current scroll position.
      const firstVisibleRow = (this.startRowIdx !== 0)
            ? this.startRowIdx + this.rowPaddingTop
            : window.scrollY / this.$refs.row.offsetHeight;

      // Find first visible item.
      let i = firstVisibleRow * oldVal;

      // If parent specifies first item to display, tee up to that item.
      if (this.firstItemShown !== undefined) {
        const j = this.items.findIndex(item => item.key === this.firstItemShown)
        if (j !== -1) {
          // Keep current first visible item to restore list display
          // when parent changes itemsPerRow again.
          this.firstItemPrevious = i;
          i = j;
        }
      } else {
        if (this.firstItemPrevious !== undefined)  i = this.firstItemPrevious;
        this.firstItemPrevious = undefined;
      }

      // Calculate new row that will contain the desired item.
      let scrollRow = i / newVal;

      // console.log('itemsPerRow was %d, now %d, start item %d, padding %d, old row %d, new row %s',
      //   oldVal, newVal, i, this.rowPaddingTop, this.startRowIdx, scrollRow.toFixed(2));

      // Round to nearest row.  Doesn't guarantee top left items remain on screen
      // but keeps position in list when resizing wide-narrow-wide or vice versa.
      scrollRow = Math.round(scrollRow);
      const listRow = Math.max(0, scrollRow - this.rowPaddingTop);
      this.setStartRow(listRow);

      // Wait for changes caused by setStartRow() to render before accessing the
      // new row height.
      this.$nextTick(() => {

          this.rowHeight = Math.floor(this.$refs.row.offsetHeight);
          const newTop = scrollRow * this.rowHeight;

          // Changing this.rowHeight changes container height, wait for this to render
          // before scrolling in case new scroll position exceeds old container height.
          this.$nextTick(() => {
            window.scroll({ top: newTop, behavior: 'auto' });
            // console.log('watch nextTick startrow %d %d, height %d, did scroll to %d scrollY %d',
            //     scrollRow, this.startRowIdx, this.rowHeight, newTop, window.scrollY);
          })
      })
    }
  },

  computed: {
    // Total height of the viewport = number of items in the array x height of each item
    viewportHeight() {
      return this.totalRows * this.rowHeight;
    },

    // Subset of items shown from the full array.  Includes padding rows above
    // and below window which are not visible.
    // Note the output array always uses a constant set of identifiers for each
    // row and each row item.  These values are provided as the :key attribute
    // in the template's v-for lists to prevent Vue from mounting and unmounting
    // the item components the parent passes in through the <slot>.  Instead Vue
    // reuses one set of item components and patches them with new item contents
    // when the user scrolls.
    renderedRows() {

      let rows = [];

      if (this.items.length === 0)  return rows;

      let rowNum = this.startRowIdx;
      let i = rowNum * this.itemsPerRow;

      // If 'this.items[]' has been swapped out with a short list, handle this gracefully.
      if (i > this.items.length)  return rows;

      // Used as the :key to the "row" element in the template.
      let rowKey = rowNum % this.renderedRowCount;

      let lastItem = i + (this.renderedRowCount * this.itemsPerRow);
      if (lastItem > this.items.length)  lastItem = this.items.length;

      // console.log('** New list starting at %d for %d entries, *itemsPerRow %d, *rowCount %d, *startRow %d, *list length %d',
      //     i, lastItem - i, this.itemsPerRow, this.renderedRowCount, this.startRowIdx, this.items.length);

      // let firstPriorityRow = 0;
      // let lastPriorityRow = this.renderedRowCount;
      // if (this.priorityFirstRow !== undefined) {
      //   firstPriorityRow = this.priorityFirstRow;
      //   lastPriorityRow = this.priorityLastRow;
      //   console.log('** New list first priority row %d, last %d', firstPriorityRow, lastPriorityRow);
      // }

      let ri = 0;
      for ( ; i < lastItem; i += this.itemsPerRow, rowNum++, ri++) {
        rows.push(
          {
            // Give this row an unique identifier to use as :key in template
            rowKey: 'r' + rowKey,

            // Note if 'i + this.itemsPerRow' is greater than length of this.items,
            // .slice() extracts only through the end.
            row: this.items
              .slice(i, i + this.itemsPerRow)
              // 'itemKey' is used as the :key to the "column" element in the template.
              // .map((obj, idx) => ({itemKey: `i${rowKey}-${idx}`, item: obj}))
              .map((obj, idx) => ({
                      item: {
                        itemKey: `i${rowKey}-${idx}`,
                        key: obj.key,  // S3 key
                        pic: obj.pic,
                        vid: obj.vid,
                        // loadFirst: (ri >= firstPriorityRow && ri <= lastPriorityRow)
                        // loadFirst: true
                      }
                  })
              )
          }
        );

        if (++rowKey >= this.renderedRowCount)  rowKey = 0;
      }
      // console.log('rows[]:', ...rows);
      return rows;
    },

    totalRows() {
      return Math.ceil(this.items.length / this.itemsPerRow);
    },

    // The amount we need to translateY the items shown on the screen to match
    // the position of the scrollbar.
    offsetY() {
      const topRow = this.startRowIdx + this.hidePaddingRows;
      // console.log('transform toprow %s rowHeight %s offset %d, hide is',
      //   topRow, this.rowHeight.toFixed(2), topRow * this.rowHeight, this.hidePaddingRows);
      return topRow * this.rowHeight;
    },

    // This is the direct list container, we apply a translateY to this
    scrollAreaStyle() {
      return {
        transform: "translateY(" + this.offsetY + "px)"
      };
    },

    viewportStyle() {
      return {
        height: this.viewportHeight + "px"
      };
    },

    cssColumnWidth() {
      // console.log('-----width for %d items', this.itemsPerRow);
      return {
        width: 'calc('+ (100 / this.itemsPerRow) + '%)'
      }
    }

  },
  methods: {

    // Update the starting index in the list of rendered rows based on the current
    // scroll position.
    updateStartRow(priorityToVisible) {

      let firstVisible = Math.round(window.scrollY / this.rowHeight);

      // Also used for 'hidePaddingRows' when resizing.
      if (priorityToVisible  &&  this.rowHeight) {
        this.priorityFirstRow = (this.startRowIdx === 0)
              ? firstVisible : this.rowPaddingTop;

        this.priorityLastRow = this.priorityFirstRow
              + Math.round(window.innerHeight / this.rowHeight);
      } else {
        this.priorityFirstRow = undefined;
        this.priorityLastRow = undefined;
      }

      const startRow = Math.max(0, firstVisible - this.rowPaddingTop);
      // let startRow = Math.round(window.scrollY / this.rowHeight) - this.rowPaddingTop;
      // startRow = Math.max(0, startRow);

      // console.log('updateStartRow() scrollTop %d, scrollY %d, total height %d, rowHeight %d, padding %d, startRow %d',
      //     this.scrollTop, window.scrollY, this.$refs.viewport.scrollHeight, this.rowHeight, this.rowPaddingTop, startRow);

      this.setStartRow(startRow);
    },

    // Slam a new value into 'startRowIdx' which triggers changes to computed
    // properties including the 'renderedRows' list and 'offsetY' which drives
    // a translateY transformation on the displayed rows.
    setStartRow(startRow) {

      this.startRowIdx = Math.floor(startRow);

      // Update 'renderedRowCount' which may trigger list recalculation
      let count = Math.ceil(window.innerHeight / this.rowHeight)
          + this.rowPaddingTop + this.rowPaddingBottom;
      count = Math.min(this.totalRows - this.startRowIdx, count);
      if (count !== this.renderedRowCount)  this.renderedRowCount = count;

      // console.log('setStartRow() %d, renderedRowCount %d', startRow, this.renderedRowCount);
    },

    //- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
    // Intersection Observer handler.
    //
    handleIntersection() {

      if (this.scrollDelay !== undefined  ||  this.resizing !== undefined) {
        // console.log('handleIntersection(): %s in progress, exiting.',
        //     this.scrollDelay !== undefined ?  'debounce' : 'resize' );
        return;
      }

      const newTop = window.scrollY;

      // Rapid scrolls through many rows causes unnecessary thrashing and
      // really delays repaint when the user finally stops.  Detect rapid
      // scrolling and start a debounce timer to delay until the user stops.
      const offTheEnd = this.renderedRowCount - this.rowPaddingBottom - 1;
      const delta = Math.abs(Math.floor((newTop - this.scrollTop) / this.rowHeight));
      if (delta >= offTheEnd) {
        console.log('delaying... delta %d', delta);
        this.waitTillScrollStops();
        return;
      }

      // To avoid expensive updating of computed properties, especially renderedRows(),
      // check that the scroll top has moved to a new row before updating the start row.
      if (delta !== 0) {
            // console.log('=======> changed scrollTop %d, client %d', this.scrollTop, newTop);
            this.scrollTop = newTop;
            this.updateStartRow(false);
      }
    },

    // Delay while user is scrolling rapidly.
    waitTillScrollStops() {
      if (window.scrollY === this.lastScrollY) {
        this.scrollDelay = undefined;
        this.lastScrollY = 0;

        console.log('- - - -> changed scrollTop after delay');
        this.scrollTop = window.scrollY;

        // Load visible thumbnails first.  Don't bother with this if there is
        // a small number of items.
        let setLoadPriority = false;
        if (this.items.length > 20) {
          console.log('Setting load flag')
          setLoadPriority = true;
          this.$store.commit('firstImageCountInit', 5);
        }

        this.updateStartRow(setLoadPriority);

      } else {
        this.lastScrollY = window.scrollY;
        clearTimeout(this.scrollDelay);
        this.scrollDelay = setTimeout(this.waitTillScrollStops, 100);
      }
    },

    //- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
    // Resize Observer handler.
    //
    // To anchor the top left item in position in the browser window while the user
    // resizes the window, the most effective and stable method is to take the
    // off-screen padding rows above the window out of the document flow while
    // resizing is in progress.  The browser maintains the top left item in place
    // very smoothly.  When resizing completes, restore the top padding.
    //
    // This technique is a two-step process.  When this handler detects the start
    // of a resize, it sets 'hidePaddingRows' non-zero which causes the 'renderedRows'
    // computed property to generate a new list with the 'hide' class set on the
    // padding rows, which enables a display:none CSS rule for those rows.  This
    // handler then runs a 250ms debounce timer until the user finishes resizing.
    //
    // When the debounce is complete, the callback zeros 'hidePaddingRows', detects
    // the new row height, calls setStartRow() to potentially adjust 'renderedRowCount',
    // then scrolls the browser to keep the first visible row in the same position.
    //
    // Some useful DOM properties:
    //    element.offsetTop: pixels from top of parent
    //    element.offsetHeight: height in pixels excluding margin (integer)
    //    element.getBoundingClientRect(): pixel position of top, bottom, left, right in viewport
    //    window.scrollY:  pixels above browser to top of page
    //    window.screen.availHeight:  maximum possible vertical resolution in CSS pixels
    //    window.innerWidth:  width including scroll bar
    //    this.$el.clientWidth:  width excluding scroll bar, i.e. CSS width: 100% on root element
    //
    // Could find first visible row by finding first row (this.$refs.scrollArea.children array)
    // with .offsetHeight >= .scrollY
    //
    // Note in experiments throttling caused a distracting rolling side effect during rapid
    // resizing
    handleResize() {

      // if (!this.$refs.row) {
      //     console.log('resize: list rows %d, windowHeight %d, $refs.row is', this.renderedRows.length, window.innerHeight, this.$refs.row);
      // } else {
      //     console.log('resize: list rows %d, windowHeight %d, height %d, $refs.row is defined',
      //         this.renderedRows.length, window.innerHeight, this.$refs.row.offsetHeight);
      // }

      if (!this.$refs.row)  return;

      // First time out of the blocks 'rowHeight' is zero.  Now that a
      // list has been rendered initialize it to the rendered height.
      if (this.rowHeight === 0) {

        this.rowHeight = Math.floor(this.$refs.row.offsetHeight);
        this.setStartRow(0);

        // console.log('resize: set rowHeight to %d', this.rowHeight);
      }

      const widthNow = this.$el.clientWidth;
      if (widthNow === this.clientWidth) {

        // No width change AND resize not already in progress
        if (this.resizing === undefined) {
          // console.log('resize observer, no width change.  Exiting.');
          return;
        }
      } else {

        // console.log('resize observer, width change from %s to %s', this.clientWidth.toFixed(2), widthNow.toFixed(2))

        // Width change detected, emit to parent
        this.clientWidth = widthNow;
        this.$emit('resize', { width: widthNow });
      }

      // Arrive here when width change OR when event occurs while
      // debounce timer 'resizing' is running.

      // When the user finishes resizing, the adjustments to row height
      // and scroll position trigger the Resize Observer again, so ignore
      // resize events during the "settling timeout".
      // if (this.settling !== undefined) {
      //   // console.log('Resize handler settling, bailing');
      //   return;
      // }

      // When resize is detected start a 250ms debounce timer.  Every time the
      // Resize Observer calls this handler this timer restarts, so no work takes
      // place until 250ms after the user stops resizing the window.  At that
      // time the timeout callback calls the resize() method which adjusts row
      // height and scroll position.
      if (this.resizing === undefined) {

        // Figure out how many rows to hide while resizing.  When the top visible
        // row is less than 'rowPaddingTop', calculate this row based on the current
        // scroll position.
        this.hidePaddingRows = (this.startRowIdx === 0)
              ? Math.round(window.scrollY / this.rowHeight)
              : this.rowPaddingTop;

        // console.log('>> Resize debounce started, scrollY %d, padding %d, startRow %d, rowHeight %d %d, hiding %d',
        //     window.scrollY, this.rowPaddingTop, this.startRowIdx, this.rowHeight, this.rowHeight, this.hidePaddingRows);

        // this.resizeAnchorPx = window.scrollY;
        // const resizeTopPadding = window.scrollY - (this.startRowIdx * this.rowHeight);
        // this.resizeTopPadRows = resizeTopPadding / this.rowHeight;
      }
      clearTimeout(this.resizing);

      // Note:  initially I reused this.resizing in handleIntersection()
      // as a settling timer.  It turned out that other work in handleIntersection()
      // triggered the resize observer, so the code would arrive here, cancel the
      // post-settling work, and execute an unintentional iteration of this timer.
      // To avoid this the settling timer is a separate object.
      this.resizing = setTimeout(() => {
        // console.log('   Resize debounce callback, startRow %d, padding %d, height %d', this.startRowIdx,
        //     this.rowPaddingTop, this.$refs.row.offsetHeight);

        this.resizing = undefined;

        const newTopRow = this.startRowIdx + this.hidePaddingRows;
        this.hidePaddingRows = 0;

        this.$nextTick(() => {

          // Set the resized row height.  Property '.offsetHeight' is an integer.
          const height = Math.floor(this.$refs.row.offsetHeight);

          // Saw this happen occasionally when this work was not done in nextTick().
          // When first rows are hidden their offsetHeight is 0, so if the rerender
          // has not happened when this work is done, height gets zeroed.
          if (height === 0) {
            console.log('##### About to set height to 0');
          }

          this.rowHeight = height;
          this.setStartRow(this.startRowIdx);  // changes renderedRowCount

          // Changing 'this.rowHeight' triggers most computed properties to update.
          // One of these is 'offsetY' which drives a CSS translateY to the new
          // vertical position.  During scrolling, this translateY moves the displayed
          // subset of the 'items' list up and down as expected.  When resize completes,
          // however, the translateY adjusts to the new position set to where the rows
          // (and overall height of the list) have shrunk or expanded.  To keep the
          // displayed portion of the list from moving, change the scroll position by
          // the same amount as the translateY so that when it happens the user sees
          // the top of the window stay in the same place.
          //
          // Near the end of the list when resizing narrow to wide, the new scroll
          // position may exceed the container height until rendering completes
          // with the updated height.  The nextTick call delays the scroll until
          // rendering is complete.
          this.$nextTick(() => {
            const newTop =  newTopRow * this.rowHeight;
            window.scroll({ top: newTop, behavior: 'auto' });

            // After resizing, we want the start row to remain unchanged so the user
            // does not see the list jump.  Set the new adjusted value in 'this.scrollTop'
            // to suppress updating the start row in the Intersection Observer handler.
            this.scrollTop = newTop;
            // console.log('<< Resize debounce DONE, scrolled to toprow %d rowHeight %d offset %d', newTopRow, this.rowHeight, newTop)
          })
        })

      }, 150);


      // With the display:none technique, adjusting this.rowHeight does not
      // appear to trigger another Resize Observer event, so the settling timer
      // is not needed.  Here it is in case you need it later:
      //
      // The size adjustments above (mainly this.rowHeight) trigger changes to
      // computed CSS rules and the scroll() call below impacts window.scrollY.
      // These changes trigger the Resize Observer event and would cause another
      // trip through the resize handler and through this function.  Set a 100ms
      // timeout to hold the resize handler and this function off until these
      // adjustments have settled.
      // clearTimeout(this.settling);
      // this.settling = setTimeout(() => {  // this is the "settling timeout"
      //     this.settling = undefined;
      // }, 100);

      // Works great:
      // // // const rowIdx = (this.startRowIdx < this.rowPadding) ? this.startRowIdx : this.rowPadding;
      // // let rowIdx = Math.round(window.scrollY / this.rowRefs[0].offsetHeight);
      // // if (rowIdx > this.rowPadding) rowIdx = this.rowPadding;
      // // console.log('Resize by pixels, startRowIdx, rowPadding, scroll to row ', startRow, this.startRowIdx, this.rowPadding, rowIdx);
      // // this.rowRefs[rowIdx].scrollIntoView(true);

      // transform: translateY technique.  Works well resizing from wide to narrow,
      // jumpy going from narrow to wide.  :(
      // const rect = this.rowRefs[0].getBoundingClientRect();  // gives fractional height
      // const y = Math.round(window.scrollY - (rect.height * this.resizeTopPadRows));
      // this.$refs.scrollArea.style.transform = "translateY(" + y + "px)";
      // console.log('scrollY %d, rows, height, y set to', window.scrollY, this.resizeTopPadRows, rect.height, y);

      // let i = this.startRowIdx;
      // if (i > this.rowPadding)  i += this.rowPadding;
      // i *= this.itemsPerRow;
      // console.log('Resize scroll to ', i);
      // this.$refs[`item${i}`].scrollIntoView(true);
    }
  },

  mounted() {

    // Width of element this component can use for display.
    this.clientWidth = this.$el.clientWidth;

    this.iObserver = new IntersectionObserver(this.handleIntersection, {threshold: 1});
    this.rObserver = new ResizeObserver(this.handleResize);
    this.rObserver.observe(this.$refs.scrollArea);

    // console.log('mounted, row height %d', this.rowHeight);

    // If list of items provided by parent is empty, do nothing further.
    if (this.items.length === 0)  return;

    // Get the row height from the rendered first 'row' element.  Note
    // that 'offsetHeight' does not include margins, but $refs.row is
    // a container element with 0 margins, so this works.
    this.rowHeight = Math.floor(this.$refs.row.offsetHeight);

    this.$nextTick(() => {

      let initialStartRow = 0;

      // Set start row to existing scroll location when user has reloaded page.
      if (sessionStorage.getItem('reloaded') !== 'true') {

        // First time load.
        sessionStorage.setItem('reloaded', 'true');

      } else {

        initialStartRow = Math.max(0,
              Math.round(window.scrollY / this.rowHeight) - this.rowPaddingTop);

        // console.log('>>>>Reload');
      }
      this.setStartRow(initialStartRow);
      // console.log('Initial scrollY is %d, row height %d, padding %d, row %d',
      //     window.scrollY, this.rowHeight, this.rowPaddingTop, initialStartRow);
    })

    // console.log('Height:', window.screen.availHeight);
    // console.log('innerheight:', window.innerHeight);
    // console.log('innerwidth:', window.innerWidth);
    // console.log('clientwidth:', this.$el.clientWidth);
    // console.log('screen:', window.screen);
  },

  beforeUnmount() {
    this.iObserver.disconnect();
    this.rObserver.disconnect();
  }
}
</script>

<style scoped>
.viewport {
  width: 100%;  /* height is set in viewportStyle() */
  overflow-y: visible;
  /* background-color: lawngreen; */
}

.row {
  width: 100%;
  display: flex;
  align-items: flex-start;
  /* background-color: yellow; */
}

.hide {
  display: none;
}

/* .scrollArea {
  background-color: orangered;
} */

/* .viewport {
  background-color: pink;
} */

/* .item { */
  /* In addition to the desired effect, this float rule also takes this
     element out of the document flow and collapses the parents to zero
     height, which kills the intersection observer's expected behavior.
     This rule requires overflow: hidden on the parent to make the parents
     expand to contain the items. */
  /* Because of the side effects of this float rule, instead use
     display: flex and align-items: flex-start on the parent to stack the
     items next to each other left to right. */
  /* float: left; */
/* } */
</style>

Component to display an individual thumbnail in a gallery controlled by the parent.
Supports "selecting" the thumbnail to allow group operations such as tagging and deleting.
When selected, the thumbnail is added to the global array of selected items in Vuex state
variable $store.state.selected[].

The parent reuses this component as much as possible by keeping a consistent set of
".itemKey" values.  As long as the key is unchanged, Vue does not mount and unmount
this component.  The intention is to minimize unnecessary work by Vue and consequent
browser delay.  When the parent changes the "source" prop, Vue patches this component
to render the changed item to the user.  Note that this component's only Vue child,
VideoPreview.vue, is conditionally rendered behind a v-if statement so typically never
goes through a lifecycle at all.  This conditional rendering is also important to
avoid Vue patching this component's children.

<template :key="source.itemKey">
    <div class="thumbGallery__item">
      <span
        :class="classThumbnail(source)"
        @click="thumbClicked(source)"
        @mouseenter="pointerEnter(source)"
        @mouseleave="pointerLeave"
      >
        <span
          class="thumbGallery__img"
        >
          <!-- <transition :name="fadeToPlayer"> -->
            <img v-if="!showVideo"
              :src="source.pic"
              :key="`img${source.key}`"
              @load="thumbLoaded"
            />
            <preview v-else
              :vidsrc="source.vid"
              :clipKey="source.key"
            />
          <!-- </transition> -->
        </span>
        <svg-icon name="check-circle"></svg-icon>
      </span>
    </div>
</template>

<script>
//transparent:
  // :src="imgLoaded ? source.pic : 'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7'"
// Can put this in in place of <preview> for simple testing...
// {{ itemKey }} thumbnail
//     <!-- <img class="thumbGallery__img" :id="source.key" :src="source.pic" /> -->
//     <label :for="source.key" v-if="useLabel" class="thumbGallery__lbl">{{source.title}}</label>


// Note:  mouseenter/mouseleave fire when the pointer enters/leaves the parent
//        element.  mouseover/mouseout fire also for the children.
// Note:  @mouseleave seems more reliable than @mouseout

import Preview from './VideoPreview.vue';
import SvgIcon from './Icon.vue';

export default {
  name: 'thumbnail',
  props: {
    source: {
      type: Object,
      default: () => {}
    }
  },

  data () {
    return {
      imgLoaded: false,
      showVideo: false
    }
  },

  components: {
    Preview,
    SvgIcon
  },

  watch: {

    // okToLoad(newVal, oldVal) {
    //   if (this.source.itemKey === 'i11-0') {
    //     console.log('okToLoad flag for %s changed from %s to %s', this.source.itemKey, oldVal, newVal);
    //   }
    // },

    '$store.state.previewActive'(newVal) {

      const showPreview = newVal === this.source.key;
      if (showPreview !== this.showVideo) {
        // console.log('%s preview for %s', showPreview ? '> Entering' : '< Leaving', this.source.key);
        this.showVideo = showPreview;
      }
    },

    // The video clip assigned to this element may change when user scrolls
    // through his library.  Parent component reuses its children as much as
    // possible to minimize browser work.
    source(newVal, oldVal) {

      // console.log('old, new', oldVal, newVal);

      if (newVal.key !== oldVal.key) {
        this.imgLoaded = false;

        // If this element is running a video preview when its 'source' changes,
        // kill the preview.
        if (oldVal.key === this.previewActive) {
          this.$store.commit('setPreviewActive', -1);
        }
        // if (this.source.itemKey === 'i11-0') {
        //   console.log('Watch key %s, id was %s is %s', this.source.itemKey, oldVal.key, newVal.key, this.okToLoad);
        // }
      }
    }
  },

  methods: {

    thumbLoaded() {
      this.imgLoaded = true;

      // if (this.source.itemKey === 'i11-0') {
      //   console.log('thumbNail loaded handler %s id %s', this.source.itemKey, this.source.key);
      // }
    },

    classThumbnail(item) {
      const baseClass = "thumbGallery__thumbnail";
      if (!item.pic) {
        return `${baseClass} ${baseClass}--empty`;
      }
      if (item.disabled) {
        return `${baseClass} ${baseClass}--disabled`;
      }

      // 'isSelected' comes before 'recentlyActive' so user can
      // select clip that was recently played.
      if (this.isSelected()) {
        return `${baseClass} ${baseClass}--selected`;
      }
      if (this.recentlyActive()) {
        return `${baseClass} ${baseClass}--active`;
      }
      return `${baseClass}`;
    },

    isSelected() {
      return this.$store.state.selected.find(item => {
        return this.source.key === item.key;
      });
    },

    recentlyActive() {
      return this.$store.state.activeClipRecent === this.source.key;
    },

    pointerEnter(item) {
      this.$store.commit('setPreviewActive', item.key);
    },

    pointerLeave() {
      this.$store.commit('setPreviewActive', undefined);
    },

    thumbClicked(item) {
      if (item.disabled) { return; }

      // When select mode is not active, click launches player.
      if (!this.selectActive) {
        this.$store.commit('setActiveClip', item.key);

      } else {

        // Select mode is active.  Clicks select thumbnails.
        if (!this.isSelected()) {
          if (this.limit > 0) {
            if (this.$store.state.selected.length < this.limit) {
              this.$store.commit('pushSelected', item);
              this.$emit("changetoselected", this.$store.state.selected);
            } else {
              this.$emit("onreachlimit", this.limit);
            }
          } else {
            this.$store.commit('pushSelected', item);
            this.$emit("changetoselected", this.$store.state.selected);
          }
        } else {
          this.$store.commit('removeFromSelected', item.key);
          this.$emit("changetoselected", this.$store.state.selected);
        }
      }
    }
  },

  computed: {

    // okToLoad() {
    //   return this.$store.state.firstImagesLoaded  ||  this.source.loadFirst;
    // },

    fadeToPlayer() {
      // return (this.imgLoaded) ? 'fade' : 'none';
      return 'none';
    },

    previewActive() {
      return this.$store.state.previewActive;
    },

    selectActive() {
      return this.$store.state.selectActive;
    }
  }
}
</script>

<style scoped>
  .thumbGallery__item {
    box-sizing: border-box;
    margin: 0.25rem;
  }

  .thumbGallery__thumbnail {
    box-sizing: border-box;
    cursor: pointer;
    position: relative;
    display: block;
    /* Height should end up 161.37 (153.37 + 8px (0.5rem)) */
    /* With border-box sizing, width should include borders and padding.
       However, testing in Firefox shows percentage calculations are based
       on the CONTENT size of the parent, including padding but not borders.
       So the padding calculation disregards this element's borders. */
    /* This calculation makes the container height for a 16:9 (56.25%)
       child with uniform spacing of 0.25rem all around.  Depends on the
       top, left positioning values of 0.25rem in .thumbGallery__img below. */
    /* When expressed as percentage (e.g. padding-bottom: 56.25%), percentage
       calculated on width of PARENT */
    padding-bottom: calc(((100% - 0.5rem) * 0.5625) + 0.5rem);
    line-height: 20px;
    border: .0625rem solid #ddd; /* = 1px when 1rem = 16px */

    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;

    -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.055);
    -moz-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.055);
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.055);

    /* -webkit-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out; */
  }

  .thumbGallery__img {
    -webkit-user-drag: none;
    display: block;
    position: absolute;
    width: calc(100% - 0.5rem);  /* percentage of CONTENT size of parent, includes padding but not parent borders */
    height: calc(100% - 0.5rem);
    /* height: 153.37px;  width 272.65 */
    top: 0.25rem; /* position the element with uniform spacing all around */
    left: 0.25rem;
  }

  .thumbGallery__img img, preview {
    position: absolute;
    width: 100%;
    max-height: 100%;
  }

  .thumbGallery__thumbnail .icon {
    display: none;
  }

  .thumbGallery__thumbnail--selected {
    background: #0088cc;
  }

  .thumbGallery__thumbnail--active {
    background: red;
  }

  .thumbGallery__thumbnail--selected .icon {
    display: block;
    position: absolute;
    top: 1em;
    right: 1em;
    width: 2em;
    height: 2em;
    fill:#0088cc;
    background-color: white;
    border-radius: 50%;
  }

  .thumbGallery__thumbnail--disabled {
    background: #b9b9b9;
    cursor: not-allowed;
  }

  .thumbGallery__thumbnail--disabled > .thumbGallery__img {
    opacity: .5;
  }

  .fade-enter-active, .fade-leave-active {
    transition: opacity 0.5s;
  }

  .fade-enter-from, .fade-leave-to {
    opacity: 0;
  }

</style>
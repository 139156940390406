<template>
<span class="tagArea" :class="unsaved ? 'isUnsaved' : ''" >
  <svg-img name="search" class="inputIcon" />
  <input autofocus ref="taginput" class="tagify--outside" :value="initialValue">
</span>
</template>

<script>
// <input autofocus ref="taginput" class="tagify--outside" placeholder="Find tags" :value="initialValue" v-on:change="onChange">

// Tagify: using version 3.22.1 of @yaireo/tagify with tweak to use tab key to autocomplete
// suggested value.  This tweak is in src/vendor/tagify/parts/event.js.
// import Tagify from "@yaireo/tagify/dist/tagify.min.js";
import Tagify from "../vendor/tagify/tagify.js";
import "@yaireo/tagify/dist/tagify.css";
import SvgImg from './SvgImg.vue';

export default {
  name: "Tags",
  props: {
    // This is the "whitelist" for Tagify: used as suggestions
    // for when user types tags.  Tags can be restricted to only
    // those in this list by setting the 'allowNew' prop to false.
    tagList: Array,
    // {
    //   type: Object,
    //   default:  () => ({
    //     whitelist: [
    //       { value: "ironman", code: "im" },
    //       { value: "antman", code: "am" },
    //       { value: "captain america", code: "ca" },
    //       { value: "thor", code: "th" },
    //       { value: "spiderman", code: "sm" }
    //     ]
    //   })
    // },

    placeholder: {
      type: String,
      default: "Find tags"
    },
    initialValue: [String, Array],
    // initialValue: {
    //   type: Array,
    //   default: () => []
    // },
    allowNew: {
      type: Boolean,
      default: false
    },
    unsaved: {
      type: Boolean,
      default: false
    },
    clearTags: {
      type: Boolean,
      default: false
    },
    // Called when tag added with event object as parameter e.g. onAdd(e).
    // Tag string is passed in e.detail.data.value
    onAdd: Function,
    // Called when user finished editing tag, parameter the same as onAdd().
    onEditComplete: Function,
    // Called when user starts editing a tag, before any changes.  Parameter
    // the same as onAdd().
    onEditStart: Function,
    // Called when tag removed, parameter the same as onAdd().
    onRemove: Function
  },
  data() {
    return {
      tagify: {},
      settings: {

        enforceWhitelist: !this.allowNew,

        whitelist:  this.tagList,

        autoComplete: {
          rightKey: true,
        },

        dropdown: {
          position: "text",
          enabled: 1
        },

        placeholder: this.placeholder,

        // These callbacks are passed an event object, say 'e'.
        // The value of the tag added or removed is in e.detail.data.value.
        callbacks: {
          "add": this.onAdd,
          "remove": this.onRemove,
          "edit:start": this.onEditStart,
          "edit:updated": this.onEditComplete
        }
      }
    }
  },

  components: {
    SvgImg
  },

  watch: {
    clearTags(newVal) {
      // console.log('Boolean prop clearTags changed...:', newVal);
      if (newVal === true) {
        this.tagify.removeAllTags();
      }
    }
  },

  mounted() {
    this.tagify = new Tagify(this.$refs.taginput, this.settings);
  }
};
</script>

<style>
/* Styles for showing tags outside of input box. */
.tagify--outside {
  border: 0;
  cursor: default;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 90%;
  height: 3em;
  padding: 0 5%;
}

.tagify--outside .tagify__input {
  border: 1px solid var(--tags-border-color);
  background-color: white;
  cursor: text;
  width: 15em;
  transition: .1s;
  position: fixed;
  left: calc(50% - 7.5em);
  top: 0.75rem;
  padding-left: 2.5em;
  margin: 0;
}

.tagify--outside .tagify__input:hover{ border-color:var(--tags-hover-border-color); }
.tagify--outside.tagify--focus .tagify__input{
  transition:0s;
  /* border-color: var(--tags-focus-border-color); */
  border-color: var(--background-dark);
}

.tagArea {
  position: fixed;
  top: 3.5rem;
  z-index: 200;
  width: 100%;
  box-shadow: 0 1px 6px 0 rgba(0,0,0,0.3);
}

.inputIcon {
  height: 1.75em;
  width: 1.75em;
  position: fixed;
  top: .85rem;
  left: calc(50% - 7em);
  z-index: 250;
  fill: #888;
}

/* Set tag to a different color when user has typed them in but
   not saved them yet.  Color is the same as the selected border. */
.isUnsaved .tagify {
  --tag-bg: #0088cc;
  --tag-text-color: white;
  --tag-remove-btn-color: white;
}

</style>

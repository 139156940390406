Component to display a modal dialog box for alert, notification, etc.
This component is rendered and appended to the top level of the DOM
by the plugin defined in modal.js.
Important note:  the plugin's $dialog.close() function must be called
when the user is done to remove this component from the DOM and release
the memory.  This is done at the end of the transition that runs when
the user acknowledges the dialog, done directly in the template using
the @afterLeave hook.

<template>
    <transition name="modal" @afterLeave="$dialog.close()">
        <div
            class="modal"
            v-show="isActive"
        >
            <transition appear name="dialog">
                <div class="dialog">
                    <span
                        class="accent"
                        :class="{ dangerColor: dangerColor }"
                    />
                    <span class="icon" v-if="icon" >
                            <span
                                class="iconCircle"
                                :class="{ dangerColor: dangerColor }"
                            >
                                <svg-img
                                    :name="icon"
                                    :class="{ dangerColor: dangerColor }"
                                />
                            </span>
                    </span>
                    <span class="heading">
                        {{ heading }}
                    </span>
                    <span class="message" v-if="message">
                        {{ message }}
                    </span>
                    <span class="buttons">
                        <button v-if="cancelButton"
                            class="button__cancel"
                            @click="close"
                        >
                            Cancel
                        </button>
                        <button
                            class="button__confirm"
                            :class="{ dangerColor: dangerColor }"
                            @click="onOk"
                        >
                            {{ confirmText }}
                        </button>
                    </span>
                </div>
            </transition>
        </div>
    </transition>
</template>

<script>
import SvgImg from './SvgImg.vue';

export default {
    name: 'Modal',

    props: {
        onConfirm: {
            type: Function,
            required: true
        },
        heading: {
            type: String,
            required: true
        },
        message: {
            type: String,
            default: ''
        },
        icon: {
            type: String,
            default: undefined
        },
        confirmText: {
            type: String,
            default: 'OK'
        },
        cancelButton: {
            type: Boolean,
            default: false
        },
        dangerColor: {
            type: Boolean,
            default: false
        }
    },

    components: {
        SvgImg
    },

    data() {
        return {
            isActive: false,
            confirmed: false
        }
    },

    mounted() {
        // console.log('props are', {...this.$props});
        this.isActive = true;
    },

    methods: {
        onOk() {
            // console.log('OK button pressed');
            this.confirmed = true;
            this.close();
        },

        close() {
            // console.log('Closing dialog');

            // Muy importante!  Must call onConfirm() to resolve
            // promise that created this component and to pass
            // confirm true or false to the parent.
            this.onConfirm(this.confirmed);
            this.isActive = false;
        }
    }

}
</script>

<style scoped>

.modal {
    display: flex;
    box-sizing: border-box;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    align-items: center;
    justify-content: center;
    color: black;
    z-index: 500;
    /* animation: fadein 0.4s; */
}

.dialog {
    min-width: 32rem;
    overflow: hidden;
    background: white;
    border-radius: 0.5rem;
    box-shadow: 1px 1px 5px rgba(0, 0, 0, .3);
    margin-bottom: 10%; /* tweak a little above center */

    /* Set opacity and scale to state when initial transition is done */
    opacity: 1;
    transform: scale(1);
}

.dialog .heading,
.dialog .message,
.dialog .buttons,
.dialog .icon {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    padding: 0.5rem 1rem;
}

.dialog .heading {
    font-size: 1.5rem;
    font-weight: 600;
    padding-top: 1.5rem;
    padding-bottom: 1rem;
    /* background: purple; */
}

.dialog .accent {
    display: block;
    width: 100%;
    height: 0.5rem;
}

.dialog .icon {
    padding-top: 1.5rem;
    padding-bottom: 0;
    margin-bottom: -0.5rem;
    fill: white;
}

.dialog .iconCircle {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.5rem;
    width: 5rem;
    height: 5rem;
    border-radius: 2.5rem;
    overflow: hidden;
    background-color: var(--action-color);
}

.dialog .message {
    max-width: 25rem;
    margin: auto;
    padding-top: 0;
    padding-bottom: 1rem;
    /* background: yellow; */
}

.dialog .buttons {
    padding-top: 1rem;
    padding-bottom: 1rem;
}

.dialog button {
    margin: 0 1rem;
    min-width: 10rem;
    height: 2.5rem;
    border-radius: 1.25rem;
}

.button__cancel {
    border: 1px solid var(--background-dark);
    background-color: white;
    color: black;
}

.button__cancel:hover {
    background-color: var(--background-medium);
}

.modal .dangerColor {
    background-color: var(--danger-color);
    color: white;
    fill: white;
}

.modal-enter-active, .dialog-enter-active {
    transition: all 0.3s;
}

.modal-enter-from {
    opacity: 0;
}

.dialog-enter-from {
    transform: scale(0.75);
}

.modal-leave-active {
    transition: opacity 0.4s ease;
}

.modal-leave-to {
    opacity: 0;
}

</style>
<template>
  <div class='container'>
    <span class='backdrop'>
      <img src="../assets/ClipZoo480px.png" alt="ClipZoo Logo">
    </span>
    <span class='content'>
      <p>ClipZoo is a service for organizing your personal video library, and for sharing
        videos privately with the people you choose, or with the whole world if you wish.  This
        website is the portal to ClipZoo cloud storage.</p>
      <p>Use the ClipZoo mobile app, available for iOS on the App Store, to capture memorable
        moments and push them here to your personal library.</p>
      <p>You can download <a href="./pdf/privacy.pdf" download="privacy.pdf">our privacy policy here</a>.</p>
    </span>
  </div>
</template>

<script>

export default {
  name: 'home',
}
</script>

<style scoped>
.container {
  position: relative;
  padding-top: 5rem;
  margin: 0 auto;
  display: block;
}

.container h1 {
  margin-bottom: 0px;
}

.container p {
  font-size: 1.25rem;
  margin: 1em 10%;
}

.container img {
  height: 80vh;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.content {
  position: relative;
}

.backdrop {
  position: absolute;
  left: 0;
  right: 0;
  opacity: 5%;
}
</style>

TagSearch.vue

Component to take user input to find tagged videos.

Maintains global list '$store.state.thumbsFiltered[]'.  When thumbsFiltered[]
contains any items, the parent can use this list to display thumbnails for
only videos that have the combination of tags the user has specified.

When thumbsFiltered[] is empty, the parent should display an alternate list
of thumbnails, for example all of them.

<template>
  <span class="searchTags" v-if="searchActive  &&  !tagEditActive">
    <tags key="search"
          :tagList="tagList"
          :onAdd="tagsFilterAdd"
          :onRemove="tagsFilterRemove"
          :clearTags="!tagsNowFiltering"
          :initialValue="tagsForSearch" />
    <svg-icon name="x" @click="tagsFilterClear"></svg-icon>
  </span>

</template>

<script>
import SvgIcon from './Icon.vue';
import Tags from './Tag.vue';

export default {
  name: "TagSearch",
  data () {
    return {
      tagsToFind: [],   // filter displayed thumbnails by these tags.  User types these into "Find tags" search box.
                        // Array of strings.
      tagsForSearch: ''
    }
  },

  components: {
    SvgIcon,
    Tags
  },

  methods: {
    // Set the list of displayed thumbnails to include only those that share
    // all the tags in 'this.tagsToFind[]'.
    tagsFilter() {
      // This is one magical line of code, adapted from Nina Scholz's Stack Overflow answer:
      // https://stackoverflow.com/questions/45236312/filtering-multiple-value-with-multiple-key-in-json-array-using-lodash
      // It iterates through the array of tagged videos.  For each one it tests that every tag
      // (the .every method on the list of tags) exists in that video's 'tags' field (v.tags.some).
      // If any tag from the list of tags is not present, the .some method returns false and the
      // .filter method excludes it from the array of results.
      //
      // Note that videoTag[] is the list from DynamoDB of all of a user's videos with all assigned tags.
      //
      // This filter expression depends on the data structures in this format:
      //     videoTag:  [{key: "12345.jpg", tags: ["fat", "yellow"]}, {}] (must have a field named 'tags')
      //     tagsToFind: ["fat", "yellow"]  (an array of strings)
      let result = this.videoTag.filter(v => this.tagsToFind.every(t => v.tags.some(f => t === f) ));
      // console.log('Results: ', result.map(r => r.key));
      // console.log('thumbsAll:', this.thumbsAll);

      // Use the filter result to update the list of thumbnails being displayed.
      const filtered = this.thumbsAll.filter(img => img.key && result.some(r => r.key.includes(img.key)));
      // console.log('filtered:', filtered);
      this.$store.commit('populateThumbsFiltered', filtered);
    },

    // Handler called in "find tags" mode when user types tags to filter videos
    // displayed in the library.  This function is called each time the user adds
    // a tag to the filter list.  New tag is passed in 'e.detail.data.value'.
    tagsFilterAdd(e) {

      // Do nothing with empty input.
      if (e.detail.data.value === '')  { return; }

      this.tagsToFind.push(e.detail.data.value);
      console.log("Adding tag %s, tag list now (tagsToFind[]):", e.detail.data.value, this.tagsToFind);
      this.tagsForSearch = JSON.stringify(this.tagsToFind);

      this.tagsFilter();
    },

    // Handler called in "find tags" mode each time the user deletes a tag from the filter.
    tagsFilterRemove(e) {

      const tag = e.detail.data.value;

      console.log("Removing tag: ", tag);

      // Do nothing with empty input.
      if (tag === '')  { return; }

      // Find the tag in the array.  Returns -1 if not found.
      let i = this.tagsToFind.indexOf(tag);
      if (i < 0) { return; }

      // Remove the tag from the array.
      this.tagsToFind.splice(i, 1);

      // If tag array is empty (probably because user deleted the last key from this filter),
      // clear the filter and display all thumbnails.
      if (!this.tagsToFind.length)  {

        this.tagsFilterClear();
        return;
      }

      console.log("Tag list after removing tag (tagsToFind[]):", this.tagsToFind);
      this.tagsForSearch = JSON.stringify(this.tagsToFind);

      this.tagsFilter();
    },

    // Clear all tags now filtering the user's displayed videos.
    tagsFilterClear() {
      this.tagsToFind.length = 0;
      this.$store.commit('clearThumbsFiltered');  // Clear list of thumbnails built from 'tagsToFind'

      // This is the work required for tagsFilterRemove() when the last
      // tag is removed
      this.tagsForSearch = '';

      this.$store.dispatch('setSearchActive', false);
    }
  },

  computed: {
    searchActive() {
      return this.$store.state.searchActive;
    },

    tagEditActive() {
      return this.$store.state.tagEditActive;
    },

    // All user-defined tags for this user.  Array of objects,
    // format: [{value: "name"}, {}]
    tagList() {
      return this.$store.state.tagList;
    },

    tagsNowFiltering() {
      return (this.$store.state.thumbsFiltered.length != 0);
    },

    thumbsAll() {
      return this.$store.state.thumbsAll;
    },

    thumbsFiltered() {
      return this.$store.state.thumbsFiltered;
    },

    // List of videos in S3 folder with user-defined tags.  Loaded from dynamoDB table.
    videoTag() {
      return this.$store.state.videoTag;
    }
  }
}
</script>

<style scoped>
  .searchTags .icon {
    color: var(--background-dark);
    position: fixed;
    top: 3.75rem;
    right: 2rem;
    z-index: 300;
    width: 2.5rem;
    height: 2.5rem;
    cursor: pointer;
  }
</style>
// Plugin to display modal dialog box.  Exposes methods:
//  this.$dialog.show()
//      Appends an element to the top level of the DOM to take user input.
//  this.$dialog.close()
//      Removes the modal dialog box from the DOM.

import Modal from './components/Modal.vue'
import { render, h } from 'vue'

// It is possible for show() to be called when a modal is already being
// displayed, most likely because the caller shows a confirm dialog as
// soon as a modal's promise resolves but before the closing animation
// completes and close() removes this first modal from the DOM.  Keep
// an array of modals to queue them as they are created so they can
// be dequeued and destroyed to avoid leaving orphan DOM nodes hanging
// in the breeze.
let modalStack = [];

export default {

    install: (app) => {

        let obj = {
            show(heading, options = {}) {

                return new Promise((resolve) => {

                    const onConfirm = (yes) => { resolve(yes === true); };
                    const props = {onConfirm, heading, ...options };
                    const m = {};

                    m.wrapper = document.createElement('div');
                    m.vNode = h(Modal, props);

                    // Give the new component the caller's 'this' context.
                    // This is important so the new element can call this
                    // plugin's close() function as this.$dialog.close().
                    m.vNode.appContext = app._context;

                    // According to Vue 3 documentation, render() does not take any
                    // arguments... but calling render() with first parameter vNode
                    // causes component lifecycle to start???
                    render(m.vNode, m.wrapper);

                    document.body.appendChild(m.wrapper);
                    modalStack.push(m);
                })
            },

            close() {
                if (modalStack.length) {
                    const m = modalStack.shift();
                    render(null, m.wrapper);
                    document.body.removeChild(m.wrapper);
                }
            }
        }

        // Make above methods available globally as e.g. this.$dialog.show().
        app.config.globalProperties.$dialog = obj;
    }
}
Source for icons is Material Design website https://material.io/resources/icons.
Other good sources are:
   Hero Icons https://heroicons.com
   https://www.iconfinder.com
   https://www.flaticon.com
Search for the icon you want then download the 24 x 24 pixel SVG version.
If the icon consists of multiple paths, you can concatenate them.  If it includes other SVG
elements like "circle", convert to a path and append the path. Here's a good article on
converting a circle to a path: https://www.smashingmagazine.com/2019/03/svg-circle-decomposition-paths

If you find an SVG you like but can't find it in 24 x 24 pixels, open it in Adobe Illustrator, Ctrl-A copy everything,
create a new file with size 24 x 24, then paste the vector graphics.  With everything still selected, set graphics
height and / or width to 22 pixels to leave one blank pixel padding.

A promising SVG editor is Inkscape, but exported SVGs had some random X,Y offset.

Also this website will scale it:
https://www.iloveimg.com/resize-image/resize-svg
Beware it bloats the image like crazy.

The VS Code extension "SVG" provides live preview and coding autofills.

<template>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
    >
      <path :d="path" />
    </svg>
</template>

<script>
const icons = {
  "check-circle":
    "M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-2 15l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z",
  // From flaticon.com
  "trash":
    "M21.229,4.143h-5.385V3.357C15.845,2.056,14.813,1,13.539,1h-3.078C9.187,1,8.154,2.056,8.154,3.357v0.785H2.769C2.345,4.143,2,4.495,2,4.928c0,0.435,0.345,0.786,0.769,0.786h0.836l1.471,16.571c0.036,0.406,0.371,0.717,0.77,0.715h12.307c0.399,0.002,0.733-0.309,0.771-0.715l1.47-16.571h0.836C21.657,5.714,22,5.363,22,4.928C22,4.495,21.657,4.143,21.229,4.143z M9.692,3.357c0-0.434,0.345-0.786,0.77-0.786h3.078c0.424,0,0.768,0.352,0.768,0.786v0.785H9.692V3.357L9.692,3.357z M17.451,21.428H6.547L5.154,5.714h3.769h9.925L17.451,21.428zM9.692,19.018c0,0,0-0.002,0-0.003L8.923,8.016c-0.031-0.434-0.399-0.76-0.825-0.729c-0.424,0.03-0.744,0.407-0.714,0.841l0.77,11c0.028,0.412,0.364,0.73,0.769,0.729h0.054C9.402,19.828,9.722,19.451,9.692,19.018zM12,7.286c-0.425,0-0.77,0.352-0.77,0.786v11c0,0.434,0.344,0.784,0.77,0.784s0.77-0.351,0.77-0.784v-11C12.77,7.637,12.425,7.286,12,7.286zM15.901,7.286c-0.425-0.03-0.795,0.296-0.824,0.73l-0.771,10.999c-0.029,0.433,0.288,0.811,0.711,0.842c0.001,0,0.002,0,0.003,0h0.057c0.404,0.001,0.741-0.317,0.768-0.729l0.771-11C16.645,7.693,16.326,7.316,15.901,7.286z",
  // From flaticon.com
  "trash-filled":
    "M21.691,4.143h-5.653V3.357C16.038,2.056,14.953,1,13.616,1h-3.231C9.046,1,7.961,2.056,7.961,3.357v0.786H2.308C1.861,4.143,1.5,4.495,1.5,4.929s0.362,0.786,0.808,0.786h0.877l1.546,16.571C4.769,22.692,5.119,23.002,5.539,23h12.923c0.419,0.002,0.77-0.308,0.809-0.715l1.544-16.571h0.877c0.448,0,0.809-0.352,0.809-0.786S22.14,4.143,21.691,4.143z M9.577,3.357c0-0.434,0.361-0.786,0.808-0.786h3.231c0.444,0,0.808,0.352,0.808,0.786v0.786H9.577V3.357L9.577,3.357z M8.831,19.857c-0.001,0-0.003,0-0.004,0H8.769c-0.425,0-0.778-0.318-0.808-0.73l-0.808-11c-0.032-0.434,0.305-0.811,0.75-0.841c0.446-0.031,0.833,0.296,0.865,0.729l0.808,11C9.61,19.448,9.275,19.825,8.831,19.857z M12.809,19.072c0,0.433-0.362,0.785-0.809,0.785s-0.808-0.353-0.808-0.785V8.071c0-0.434,0.362-0.786,0.808-0.786s0.809,0.352,0.809,0.786V19.072z M16.846,8.127l-0.808,11c-0.029,0.412-0.383,0.73-0.808,0.73h-0.058c-0.445-0.03-0.781-0.406-0.749-0.839c0,0,0-0.002,0-0.003l0.807-11c0.032-0.434,0.419-0.761,0.865-0.73C16.543,7.316,16.877,7.693,16.846,8.127z",
  "x":
    "M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z",
  "x-circle":
    "M12 2 C6.48 2 2 6.48 2 12 s4.48 10 10 10 10-4.48 10-10 S17.52 2 12 2zm7 4.41 L13.41 12 19 17.59 17.59 19 12 13.41 6.41 19 5 17.59 10.59 12 5 6.41 6.41 5 12 10.59 17.59 5z",
  "search":
    "M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z",
  "label":
    "M21.41 11.58l-9-9C12.05 2.22 11.55 2 11 2H4c-1.1 0-2 .9-2 2v7c0 .55.22 1.05.59 1.42l9 9c.36.36.86.58 1.41.58s1.05-.22 1.41-.59l7-7c.37-.36.59-.86.59-1.41s-.23-1.06-.59-1.42zM13 20.01L4 11V4h7v-.01l9 9-7 7.02zM5 6.5 a1.5 1.5 0 1 0 3 0 1.5 1.5 0 1 0 -3 0z"
}
export default {
  name: "SvgImg",
  props: {
    name: String
  },
  computed: {
    path() {
      return icons[this.name];
    }
  }
};
</script>

<template>
  <div class='site'>
    <div class='header'>
      <div class='header-menu-area'>
        <img class='header-img' src="../assets/headerimg.png" />
        <router-link to="/home" class='heading'>ClipZoo</router-link>
        <div class='header-menu'>
          <button @click="enableSelect" v-if="currentPath === '/library'">Select</button>
          <svg-icon name="search"  @click="enableSearch" v-if="currentPath === '/library' &&  !searchActive"></svg-icon>
          <router-link class='link' to="/library" v-if="isAuthenticated  &&  currentPath !== '/library'">Library</router-link>
          <p class='link' v-on:click="signOut" v-if="isAuthenticated">Sign Out</p>
          <router-link class='link' to="/" v-if="!isAuthenticated && currentPath !== '/'">Sign In</router-link>
        </div>
      </div>
    </div>
    <router-view></router-view>
  </div>
</template>

<script>
// From template for additional nav link to protected route:
// <router-link class='link' to="/protected" v-if="isAuthenticated &&  currentPath !== '/protected'">Feed</router-link>
// <router-link to="/home" class='link' v-if="currentPath !== '/home'">Home</router-link>

import SvgIcon from './Icon.vue';

export default {
  name: 'app',
  components: {
    SvgIcon
  },
  async beforeCreate() {
    try {
      const user = await this.$Auth.currentAuthenticatedUser();
      this.$store.dispatch('setIsAuthenticated', true);
      this.$store.dispatch('setUser', user);
      if (this.currentPath !== '/library') this.$router.push('library');
    } catch (err) {
      // console.log('error: ', err);
    }
  },
  computed: {
    isAuthenticated () {
      return this.$store.state.isAuthenticated;
    },
    searchActive () {
      return this.$store.state.searchActive;
    },
    currentPath () {
      return this.$route.path;
    }
  },
  methods: {
    async signOut() {
      try {
        await this.$Auth.signOut();
        this.$store.commit('clearUser'); // clears 'user', 'userFolder', and 'isAuthenticated'
        this.$router.push('/');
      } catch (err) {
        console.log('Error signing out:', err);
      }
    },
    enableSearch() {
      this.$store.dispatch('setSearchActive', true);
    },
    async enableSelect() {

      // const value = await this.$dialog.show(
      //     'Cause irreparable damage?',
      //     {
      //       message: "You're really going to fuck things up.  I can't believe what an idiot you are.",
      //       cancelButton: true,
      //       confirmText: "I'm an idiot",
      //       icon: 'trash-filled',
      //       dangerColor: true
      //     });
      // console.log('Dialog promise resolved with', value);
      // if (value === true) {
      //   this.$dialog.show("You really are an idiot!!");
      // }

      this.$store.commit('setSelectActive', true);
    }
  }
}
</script>

<style scoped>
.link {
  text-align: right;
  font-weight: 600;
  cursor: pointer;
  margin-left: 1.75rem;
  color: var(--text-dark);
  text-decoration: none;
}

.header {
  position: fixed;
  top: 0;
  box-sizing: border-box;
  width: 100%;
  z-index: 100;
}

.header-menu-area {
  height: 3.5rem;
  display: flex;
  background-color: var(--background-light);
  padding: .5rem 2rem;
  box-shadow: 0 1px 2px 0 rgba(60,64,67,0.3), 0 2px 6px 2px rgba(60,64,67,0.15);
  align-items: center;
}

.header-menu {
  flex: 1;
  padding-right: 2em;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.header-menu button {
    background-color: var(--background-dark);
    margin-left: 1.75rem;
}

.header .icon {
  width: 2em;
  height: 2em;
  margin-left: 1.75rem;
  cursor: pointer;
}

.heading {
  color: #606368;
  text-align: left;
  margin: 4px;
  font-size: 1.5em;
  font-weight: 400;
  text-decoration: none;
}

.header-img {
  height: 100%;
  padding-right: 1em;
}

</style>

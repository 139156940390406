Puts a wrapper around an SVG image that allows it to be used as a clickable input.

<template>
  <div class="icon">
    <svg-img :name="name" />
  </div>
</template>

<script>
import SvgImg from './SvgImg.vue';

export default {
  name: "SvgIcon",
  props: {
    name: String
  },
  components: {
    SvgImg
  }
};
</script>

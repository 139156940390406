Library.vue
This component manages access to a specific user folder on an S3 bucket.  The goal is to allow
restricted access to a user's own folder on a bucket with no public access.  The
name of the folder is derived from the email address the user provides when signing up.

To Do
- Update Lambda function that runs off Cognito trigger sam-cognito to prevent it
  from allowing the / character in the ciphered folder name.
- Limit user upload to maximum allocated space
  * Client: Compare space used to total in user record. If discrepancy call API audit/reset Lambda
  * Create S3 trigger Lambda to update space used in user record
  * In Lambda that provides signed URL for upload, error if size exceeds allocated space
    iOS note:  using Amplify library does not appear to be easy way to retrieve signed URL
    to access S3.  Provide API to return available upload space for user and make decision
    in mobile app?
- Convert MP4s to have index at head of file for quick playback

- Delete videos
  * in delete dialog:  focus on button, respond to ESC, CR, etc.
  * implement Lambda to permanently delete after elapsed time
- Video player
  * thumbnails on timeline... plyr.io?
  * transition is lumpy
- Tooltip for buttons
- Support sorting thumbnails
- Support "or", "not" in tag filtering
  * Idea:  include / exclude slider next to input box.  Box / slider / tag are green for
    include, red for exclude
  * Side slideover window for search options
  * Apple NSPredicateEditor complex but comprehensive
  * Searched "ux search exclude", can also search "ux filter"
  * See Ikea, allposters websites
- Ability to save searches as "views" or "folders"
CSS:
- RESPONSIVE!!!
- Video player in-place vs full screen
- Responsive 1x1 gallery
- Work on home screen and appearance for visitors not logged in
Bugs:
- With tag search active, page reload does not initialize the tagify instance.  To cause this,
  open search, go to "Home" route and back to "Library". "Find tags" doesn't work right.
- After sign up, prompt at bottom is incorrect.
- FYI 0 byte clips on S3: 20171001_12-30-01PM.mp4, 20161223_11-01-39AM.mp4
  * Handle this kind of error by deleting thumbnail and object.

<template>
  <div class='container'>

    <div :class="selectActive ? 'action-bar' : 'action-bar--hidden'">
      <span>{{ selectedList.length }} selected</span>
      <svg-icon name="trash" @click="deleteClip" v-if="selectedList.length"></svg-icon>
      <svg-icon name="label" @click="startEditing" v-if="!tagEditActive"></svg-icon>
      <svg-icon name="x" @click="clearSelected"></svg-icon>
    </div>
    <tag-search v-if="searchActive  &&  !tagEditActive" />
    <tag-edit  v-if="tagEditActive" />

    <!-- <transition name="playermove" appear> -->
    <div class="player-area" v-if="playerUrl">
      <div class="clip">
        <Player
          class="player"
          ref="player"
          playsinline
          debug
        >
          <!-- <Video :poster="posterTitle"> -->
          <Video>
            <source
              :data-src="playerUrl"
              type="video/mp4"
            />
          </Video>
          <DefaultUi />
        </Player>
      </div>
      <svg-icon name="x" @click="clearPlayer"></svg-icon>
    </div>
    <!-- </transition> -->
    <Thumbs :imgArray="images"></thumbs>

  </div>
</template>

<script>
// From template:
        // <Player
        //   ref="player"
        //   playsinline
        //   muted
        //   debug
        //   @vmReady="playerReady"
        //   @vmPlaybackReady="playbackReady"
        // >

    // <thumbs v-if="loaded" :imgArray="images" ></thumbs>
// <video controls width="640" height="360" v-html="videoTitle" />

// For future use / reference.  Inline GIF to use a placeholder in <img> src property:
//data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7

// Library from https://github.com/RobinCK/vue-gallery
// import VueGallery from 'vue-gallery';

import Thumbs from './ThumbGallery.vue';
import SvgIcon from './Icon.vue';
import TagEdit from './TagEdit.vue';
import TagSearch from './TagSearch.vue';
// import '@vime/core/themes/default.css';  // This CSS is included directly at the bottom of this file, see comment there...
import { Player, Video, DefaultUi } from '@vime/vue-next';

// import * as tagData from './TagTest.json';

export default {
  name: 'library',
  data() {
    return {
      loaded: false
    }
  },

  components: {
    Thumbs,
    SvgIcon,
    TagEdit,
    TagSearch,
    Player,
    Video,
    DefaultUi
  },

  watch: {

    // '$store.state.firstImagesLoaded'(complete) {
    //   if (complete) {
    //     this.firstLoadComplete();
    //   }
    // },

    '$store.state.urlsCreated'(complete) {
      if (complete) {
        console.log('Refreshing thumbsAll[] with balance of URLs, elapsed %d ms', (performance.now() - this.$store.state.startTime));
        this.$store.commit('refreshThumbsAll', this.videoTag);
        // console.log('Appending the balance of URLs to thumbsAll[], elapsed %d ms', (performance.now() - this.$store.state.startTime));
        // this.$store.commit('appendThumbsAll', this.videoTag.slice(this.thumbsAll.length));
        // for debug:  append only another 100 thumbnails...
        // this.$store.commit('appendThumbsAll', this.videoTag.slice(this.thumbsAll.length, this.thumbsAll.length + 100));
      }
    },

    '$store.state.searchActive': 'tagsFind'
  },

  async created() {

    try {

      // Need to pass $Auth into Vuex module since it doesn't access
      // the Vue instance.  Vue 2 and Vue 3 handle this differently, this
      // method does not depend on which version of Vue.
      await this.$store.dispatch('setCredentials', this.$Auth);

      this.$store.commit('setStartTime');
      this.firstLoad();

    } catch (err) { console.log('error: ', err); }
  },

  // mounted() {
      //  console.log('window.innerwidth, clientwidth, innerHeight, clientHeight ',
      //    window.innerWidth, this.$el.clientWidth, window.innerHeight, this.$el.clientHeight);

  //   document.onreadystatechange = () => {
  //     console.log('Ready state now ', document.readyState);
  //     if (document.readyState == "complete") {
  //       console.log('Page completed with image and files!')
  //     }
  //   }
  // },

  methods: {
    // User has pressed the tag icon in the action bar after selecting one or
    // more thumbnails. Change to "tag edit" mode.
    startEditing() {
      if (0 === this.selectedList.length) { return; }

      // Setting this flag causes the computed property for this.images[] to switch
      // to this.selectedList[].
      this.$store.commit('setTagEditActive', true);
    },

    // Event handler fired when user selects or unselects a thumbnail.  To use this,
    // add  @changetoselected="changeToSelected" in the template for the <thumbs> element.
    // Note that the array of selected items is in the global $store.state model.
    // changeToSelected(data) {
    //   // console.log('Event changeToSelected: ', data);
    //   this.selectedList = data;
    // },

    // classActionBar() {
    //   return (this.selectedList.length !== 0) ? 'action-bar' : 'action-bar--hidden';
    // },

    // User has pressed the "X" icon in the action bar.  Clears all selected items.
    clearSelected() {
      // Setting these flags causes the computed property for this.images[] to
      // switch from selectedList[] and restore the thumbnail display.  If user
      // has specified a filter, show the filtered thumbnails.  Otherwise, show
      // the unfiltered ones.
      this.$store.commit('clearSelected');
      this.$store.commit('setSelectActive', false);
      this.$store.commit('setTagEditActive', false);
    },

    // User has pressed the "X" icon on the video player.
    clearPlayer() {
      this.$store.commit('clearActiveClip');
    },

    async deleteClip() {
      let clip = 'clips';
      let phrase = 'they are'
      if (this.selectedList.length === 1) {
        clip = 'clip';
        phrase = 'it is'
      }
      try {

        // Prompt user for confirmation.
        const confirm = await this.$dialog.show(
            `Delete ${this.selectedList.length} ${clip}?`,
            {
              message: `You have three days to recover the ${clip} before ${phrase} PERMANENTLY DELETED.`,
              cancelButton: true,
              confirmText: `Delete ${clip}`,
              icon: 'trash-filled',
              dangerColor: true
            });
        if (confirm === true) {

          const result = await this.$store.dispatch('deleteClips', this.selectedList);
          if (result === true) {
            this.$dialog.show(`${this.selectedList.length} ${clip} deleted.`);
            this.clearSelected();
          } else {
            this.$dialog.show('There was a problem deleting.');
          }
        }
      } catch  (err) { console.log('Delete clip(s) failed with error: ', err); }
    },

    tagsFind() {
      console.log("Search clicked");
    },

    // Retrieve a list of all items in the user's folder on S3.  Also populates
    // an initial list of thumbnails to display and generates signed URLs so
    // Vue can fetch the thumbnails.
    async firstLoad() {
      console.log('Retrieving list of objects in folder...');

      if (this.loaded) {
        console.log('Skipping thumbnail download, flag indicates already done.');
        return;
      }

      // Not using try..catch here because error is handled in the
      // 'fetchVideoTag' action.  If it fails, this.videoTag[] is
      // unchanged (an empty array), which is a valid condition and
      // causes no harm in initializing the additional variables below.
      await this.$store.dispatch('fetchVideoTag');

      //- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
      // Create the list to contain URLs for all thumbnails and video clips,
      // thumbsAll[].  This list, or filtered and sorted subsets of it,
      // will be assigned to images[], which is the list of thumbnails
      // displayed to the user.
      //
      // To make initial page load as snappy as possible, we create 30
      // thumbnail signed URLs and 30 video URLs and populate them into
      // images[] to get the browser fetching visible thumbnails ASAP.
      //
      // Initial page load (Chrome performance profiler):
      //    1.8s    First images[] that ThumbGallery sees contains 30
      //            elements.
      //    3.25s   First images[] contains 1,000 elements but only
      //            first 30 have '.pic' property filled in, and
      //            Thumbnail.vue has v-if="image.pic" in <preview>,
      //            that is Vue renders only a blank outlined placeholder.
      //
      // It is critical to hold Vue rendering off while all this work
      // is being done, otherwise initial page load time spirals up
      // into tens of seconds.
      //
      // Once the page is loaded, the remaining URLs are created and
      // inserted into thumbsAll[].  The thumbGallery component lazy
      // loads thumbnails as required using an intersection observer.

      // let t0 = performance.now();

      // this.images = Array.from({length: this.videoTag.length}, (_, i) => (
      //           { id: i,
      //             key: '',
      //             pic: '',
      //             vid: '',
      //             title: ''
      //           }
      // ));

      // Set the initial number of URLs to load before enabling Vue to render.
      const max = 30;

      // Performance note:  getSignedUrl() takes longer for the first one, about
      // 20ms.  After that it is pretty quick to fetch additional ones, about 4ms
      // each.  100 takes about 200ms (2ms each), 1,000 takes 1.8s (1.8ms each).
      await this.$store.dispatch('createSignedUrls', max);
      // let t1 = performance.now();
      // console.log('%d signed URLs created in %d ms', max, (t1-t0));

      // Allow Vue to start rendering.  Initialize a counter in the state store
      // to set a 'firstImagesLoaded' flag when the first set of images have been
      // downloaded.  At that point the user has an expedited screen full of
      // thumbnails, and work can continue to generate URLs for all resources
      // in the user's library without making the user wait.
      // THIS TECHNIQUE IS OBSOLETE IN THIS CODE, no longer using lazy-loading,
      // speed of image load is handled by VirtualScroll.vue
      // this.$store.commit('firstImageCountInit', max);
      // this.$store.commit('firstRenderCountInit', 1847);

      // this.$store.commit('populateThumbsAll', this.videoTag.slice(0, max));
      this.$store.commit('refreshThumbsAll', this.videoTag);

      // Typically the 'pic' field is left blank to be filled in later when the
      // lazy-loading mechanism says it is time for the browser to start
      // fetching.  In this case, short-circuit lazy-loading and force the
      // browser to fetch this initial batch of thumbnails.
      // for (const obj of this.thumbsAll) {

      //   this.$store.commit('lazyLoadThumbsAll', obj.id);
      // }

      // console.log(this.images);
      // let t1 = performance.now();
      // Using computed property for images[], ~130ms to here (same as populating it directly)
      // console.log('Array of first %d images populated, %d ms elapsed.', this.thumbsAll.length, (t1-t0));

      // Create all the remaining signed URLs.  This launches a worker task that
      // runs on a different thread so it doesn't slow down the browser.
      const list = this.videoTag.slice(max).map(v => ({key: v.key}));
      this.$store.dispatch('createSignedUrlsWorker', list);

      // Retrieve all the tags this user has defined. Create array
      // this.tagList[] in format for Tagify (Tags.vue) whitelist.
      // Build sorted array of all tags including duplicates.
      // const allTags = this.videoTag.map(v => v.tags).flat().sort();
      const allTags = this.videoTag.flatMap(v => v.tags).sort();

      // Remove duplicates, same techinque used in tagsEditSave().
      const tags = allTags.filter((t, idx) => allTags.indexOf(t) === idx);

      this.$store.commit('populateTagList', tags);

      // this.tagList = this.videoTag.map(v => v.tags.map(t => ({ 'value': t }))).flat(); // this works, includes duplicates
      // console.log('All user tags (tagList[]):', this.tagList.map(t => t.value));
      this.loaded = true;
    },

    // The initial page load, including the first batch of thumbnails, is
    // complete.  Carry on loading additional resources now that it will
    // not interfere with and delay the user's experience with their library.
    // async firstLoadComplete() {
    //   // Using computed property for this.images[], 720-850ms to here, maybe 20ms slower than direct access.
    //   // Launching worker in firstLoad(), min time to here about 750ms.
    //   console.log('Vuex says first images now loaded, elapsed %d ms', (performance.now() - this.$store.state.startTime));
    // }
  },

  computed: {

    // Thumbnails displayed.  Could be thumbsFiltered[], thumbsAll[], or selectedList[]
    images() {

      let list = [];
      let listId = 1;

      // When user chooses tag edit mode and some thumbnails are selected,
      // display selectedList[].
      if (this.tagEditActive &&  this.selectedList.length) {
        list.push(...this.selectedList);
        listId = 3;

      // If not in tag edit mode and user has filtered the list by specifying
      // some tags, display thumbsFiltered[]
      } else if (this.$store.state.thumbsFiltered.length) {
        list.push(...this.$store.state.thumbsFiltered);
        listId = 2;

      // Otherwise, display the entire list of thumbnails.
      } else {
        list.push(...this.$store.state.thumbsAll);
      }

      this.$store.commit('setListDisplayed', listId);

      return list;
    },

    playerUrl() {
      return this.$store.state.activeClipUrl;
    },

    selectActive() {
      return this.$store.state.selectActive;
    },

    selectedList() {
      return this.$store.state.selected;
    },

    thumbsAll() {
      return this.$store.state.thumbsAll;
    },

    thumbsFiltered() {
      return this.$store.state.thumbsFiltered;
    },

    searchActive() {
      return this.$store.state.searchActive;
    },

    tagEditActive() {
      return this.$store.state.tagEditActive;
    },

    username() {
      return this.$store.state.user.username;
    },

    userFolder() {
      return this.$store.state.userFolder;
    },

    // List of videos in S3 folder with user-defined tags.  Loaded from dynamoDB table.
    videoTag() {
      return this.$store.state.videoTag;
    }
  }

  // filters: {
  //   pretty: function(value) {
  //     return JSON.stringify(value, null, 4);
  //   }
  // }
}

</script>

<style scoped>
  .container {
    display: flex;
    box-sizing: border-box;
    width: 100%;
  }

  /* .image {
    float: left;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    width: calc(20% - 4px);
    padding-top: calc(15% - 4px);  percentage of width of PARENT.  For 3:4 aspect ratio, this is 3/4 of width value
    position: relative;
    margin: 2px;
  }

  .image-select {
    position: absolute;
    width: calc(100% - 10px); 10px + 10px (margin) + 5px + 5px (border)
    top: 0;
    bottom: 0;
    border: 5px solid transparent;
  } */

  .action-bar {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    position: fixed;
    top: 0;
    z-index: 200;
    box-sizing: border-box;
    width: 100%;
    height: 3.5em;
    padding-right: 1em;
    background-color: black;
  }

  .action-bar span {
    padding-right: 2em;
    color: white;
  }

  .action-bar .icon {
    width: 2.5em;
    height: 2.5em;
    margin-right: 2em;
    fill: white;
    cursor: pointer;
  }

  .action-bar--hidden {
    display: none;
  }

  /* .container button {
    margin-right: 2em;
    background-color: #0088cc;
  } */

  /* button.clearTags {
    background-color: var(--background-dark);
    position: fixed;
    top: 4rem;
    right: 2rem;
    z-index: 300;
  } */

  /* This is an invisible div whose purpose is to occupy the left
     portion of the flexbox and shrink the thumbnail gallery to the right.  */
  .player-area {
    /* Set flex-shrink to 0 so this flex div maintains specified width.  Adjacent
       flex div(s) will fill remaining space. */
    flex-shrink: 0;
    width: 70%;

    /* Padding should make no difference since this div has
       box-sizing: border-box.  But somehow with flexbox it does
       make a difference on width when sharing the box with another
       flex item (.thumbGallery here).  So add padding to match
       the other flex item. */
    /* padding-left: 5%; */
  }

  /* Draws on top of .clip */
  .player-area .icon {
    position: fixed;
    display: block;
    cursor: pointer;
    top: 7.5rem;
    left: 2.5%;
    margin-left: 1rem;
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 50%;
    fill: white;
    background-color: rgba(114, 114, 114, 0.7); /* this is background-dark #727272 with opacity 70% */
  }

  .player-area .icon:hover {
    background-color: var(--background-dark);
  }

  .clip {
    display: block;
    position: fixed;
    top: 7rem; /* place below tag display area */
    left: 2.5%;
    width: 65%;
  }

  .playermove-enter-from, .playermove-leave-to {
    opacity: 0;
    width: 0;
  }

  .playermove-enter-active, .playermove-leave-active {
    transition: 0.5s;
  }

  /* These are from node_modules/@vime/core/themes/default.css but
     when building for production, Webpack does not bundle this correctly.
     This is mentioned in the Vime documentation but the suggested
     plugin does not work: https://vimejs.com/getting-started/installation */
  vm-player {
  --vm-color-dark: #313131;
  --vm-color-gray-100: rgba(0, 0, 0, 0.1);
  --vm-color-gray-200: rgba(0, 0, 0, 0.27);
  --vm-color-gray-300: rgba(0, 0, 0, 0.38);
  --vm-color-gray-500: rgba(0, 0, 0, 0.64);
  --vm-color-gray-600: rgba(0, 0, 0, 0.7);
  --vm-color-white-100: rgba(255, 255, 255, 0.1);
  --vm-color-white-200: rgba(255, 255, 255, 0.27);
  --vm-color-white-700: rgba(255, 255, 255, 0.87);
  --vm-fade-transition: opacity 0.3s ease-in-out;
  --vm-media-z-index: 0;
  --vm-blocker-z-index: 1;
  --vm-ui-z-index: 2;
  --vm-loading-screen-z-index: 1;
  --vm-poster-z-index: 5;
  --vm-scrim-z-index: 10;
  --vm-click-to-play-z-index: 20;
  --vm-dbl-click-fullscreen-z-index: 20;
  --vm-captions-z-index: 30;
  --vm-spinner-z-index: 40;
  --vm-controls-z-index: 50;
  --vm-tooltip-z-index: 60;
  --vm-menu-z-index: 70;
  --vm-skeleton-z-index: 100;
  --vm-player-bg: #000;
  --vm-player-border-radius: 4px;
  --vm-player-font-family: 'Helvetica Neue', 'Segoe UI', Helvetica, Arial, sans-serif;
  --vm-loading-screen-dot-size: 12px;
  --vm-loading-screen-pulse-duration: 1.5s;
  --vm-loading-screen-dot-color: var( --vm-player-theme, var(--vm-color-white-700));
  --vm-skeleton-color: hsl(0, 10%, 90%);
  --vm-skeleton-sheen-color: hsl(0, 10%, 97%);
  --vm-slider-thumb-width: 13px;
  --vm-slider-thumb-height: 13px;
  --vm-slider-thumb-bg: #fff;
  --vm-slider-thumb-border: 2px solid transparent;
  --vm-slider-track-height: 3px;
  --vm-slider-track-focused-height: 5px;
  --vm-slider-track-color: var(--vm-color-white-200);
  --vm-slider-value-color: var(--vm-player-theme, #fff);
  --vm-tooltip-border-radius: 3px;
  --vm-tooltip-font-size: 14px;
  --vm-tooltip-padding: calc(var(--vm-control-spacing) / 1.5);
  --vm-tooltip-fade-duration: 0.2s;
  --vm-tooltip-fade-timing-func: ease;
  --vm-tooltip-spacing: 14px;
  --vm-tooltip-box-shadow: 0 0 2px var(--vm-color-gray-500);
  --vm-tooltip-bg: var(--vm-color-dark);
  --vm-tooltip-color: var(--vm-color-white-700);
  --vm-spinner-width: 80px;
  --vm-spinner-height: 80px;
  --vm-spinner-thickness: 3px;
  --vm-spinner-fill-color: #fff;
  --vm-spinner-track-color: var(--vm-color-white-200);
  --vm-spinner-spin-duration: 1.1s;
  --vm-spinner-spin-timing-func: linear;
  --vm-scrim-bg: var(--vm-color-gray-300);
  --vm-captions-text-color: #fff;
  --vm-captions-font-size: 18px;
  --vm-captions-font-size-medium: 22px;
  --vm-captions-font-size-large: 24px;
  --vm-captions-font-size-xlarge: 28px;
  --vm-captions-cue-bg-color: var(--vm-color-gray-600);
  --vm-captions-cue-border-radius: 2px;
  --vm-captions-cue-padding: 0.2em 0.5em;
  --vm-controls-bg: transparent;
  --vm-controls-border-radius: 4px;
  --vm-controls-padding: var(--vm-control-spacing);
  --vm-controls-spacing: var(--vm-control-spacing);
  --vm-control-group-spacing: var(--vm-control-spacing);
  --vm-control-border: 0;
  --vm-control-scale: 1;
  --vm-control-border-radius: 3px;
  --vm-control-spacing: 8px;
  --vm-control-padding: 4px;
  --vm-control-icon-size: 28px;
  --vm-control-color: #fff;
  --vm-control-tap-highlight: var(--vm-color-white-200);
  --vm-control-focus-color: #fff;
  --vm-control-focus-bg: var(--vm-player-theme, var(--vm-color-white-200));
  --vm-scrubber-loading-stripe-size: 25px;
  --vm-scrubber-buffered-bg: var(--vm-color-white-200);
  --vm-scrubber-loading-stripe-color: var(--vm-color-white-200);
  --vm-scrubber-tooltip-spacing: 10px;
  --vm-time-font-size: 14px;
  --vm-time-font-weight: 400;
  --vm-time-color: var(--vm-color-white-700);
  --vm-menu-color: var(--vm-color-white-700);
  --vm-menu-bg: var(--vm-color-dark);
  --vm-menu-font-size: 14px;
  --vm-menu-font-weight: 400;
  --vm-menu-transition: transform 0.25s ease-out;
  --vm-menu-item-padding: 8px;
  --vm-menu-item-focus-color: var(--vm-menu-color);
  --vm-menu-item-focus-bg: var(--vm-color-white-100);
  --vm-menu-item-tap-highlight: var(--vm-color-white-100);
  --vm-menu-item-hint-color: var(--vm-menu-color);
  --vm-menu-item-hint-font-size: 13px;
  --vm-menu-item-hint-opacity: 0.54;
  --vm-menu-item-badge-color: var(--vm-menu-color);
  --vm-menu-item-badge-bg: transparent;
  --vm-menu-item-badge-font-size: 10px;
  --vm-menu-item-arrow-color: var(--vm-menu-color);
  --vm-menu-item-check-icon-size: 16px;
  --vm-menu-item-divider-color: var(--vm-color-white-100);
  --vm-settings-width: 275px;
  --vm-settings-padding: 8px;
  --vm-settings-max-height: 75%;
  --vm-settings-border-radius: 2px;
  --vm-settings-shadow: 0 0 8px 2px var(--vm-color-gray-100);
  --vm-settings-scroll-width: 10px;
  --vm-settings-scroll-thumb-color: var(--vm-color-white-200);
  --vm-settings-scroll-track-color: var(--vm-menu-bg);
  --vm-settings-transition: transform 0.2s cubic-bezier(0, 0, 0.4, 1) 0.16s, opacity 0.2s cubic-bezier(0, 0, 0.4, 1) 0.16s
}

vm-player[video] {
  --vm-tooltip-spacing: 18px
}

vm-player[mobile], vm-player[touch] {
  --vm-control-border-radius: 50%
}

vm-player[mobile] {
  --vm-settings-width: 100%;
  --vm-menu-control-padding: 12px calc(var(--vm-control-padding) * 2)
}

vm-player[audio] {
  --vm-controls-bg: var(--vm-color-dark);
  --vm-settings-max-height: 200px;
  --vm-tooltip-spacing: 10px
}

</style>
